import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import Card from './Card';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import 'moment/locale/fr';
import Modal from './Modal';

moment.locale('fr');

const Section = styled.div`
  position: relative;
  width: 100%;
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
`;

const ProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const NoVoyantContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVoyantMessage = styled.p`
  color: #333;
  font-size: 20px;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #DFB01A;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 20;
  ${({ direction }) => (direction === 'left' ? 'left: -50px;' : 'right: -50px;')}
`;

const CarouselPrivate = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      const querySnapshot = await getDocs(collection(db, 'Voyants'));
      const profilesData = await Promise.all(querySnapshot.docs.map(async (voyantDoc) => {
        const planningDoc = await getDoc(doc(voyantDoc.ref, 'planning', 'planningData'));
        const planningData = planningDoc.data() || {};
        return {
          id: voyantDoc.id,
          ...voyantDoc.data(),
          planning: planningData,
        };
      }));
      const availableProfiles = profilesData.filter(profile => isAvailableNow(profile.planning));
      setProfiles(availableProfiles);
    };
    fetchProfiles();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getCurrentQuarterHour = () => {
    const now = moment();
    const minutes = now.minutes();
    const hour = now.format('HH');
    if (minutes < 15) {
      return `${hour}h-0`;
    } else if (minutes < 30) {
      return `${hour}h-1`;
    } else if (minutes < 45) {
      return `${hour}h-2`;
    } else {
      return `${hour}h-3`;
    }
  };

  const isAvailableNow = (planning) => {
    const now = moment();
    const currentDay = capitalizeFirstLetter(now.format('dddd'));
    const currentQuarterHour = getCurrentQuarterHour();

    return planning && planning[currentDay] && planning[currentDay][currentQuarterHour] === 'green';
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => prev === 0 ? profiles.length - getCardsPerPage() : Math.max(prev - getCardsPerPage(), 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => prev + getCardsPerPage() >= profiles.length ? 0 : Math.min(prev + getCardsPerPage(), profiles.length - getCardsPerPage()));
  };

  const handleCardClick = (profile) => {
    setSelectedProfile(profile);
  };

  const closeModal = () => {
    setSelectedProfile(null);
  };

  const getCardsPerPage = () => {
    if (window.innerWidth >= 1024) {
      return 5;
    } else if (window.innerWidth >= 600) {
      return 3;
    } else {
      return 1;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev()
  });

  const translateX = -(currentIndex * (100 / getCardsPerPage()));

  if (profiles.length === 0) {
    return (
      <ProfileWrapper>
        <NoVoyantContainer>
          <NoVoyantMessage>Pas de voyant disponible</NoVoyantMessage>
        </NoVoyantContainer>
      </ProfileWrapper>
    );
  } else if (profiles.length < 5) {
    return (
      <ProfileWrapper>
        {profiles.map((profile) => (
          <Card profile={profile} onClick={handleCardClick}/>
        ))}
        <Modal show={selectedProfile !== null} onClose={closeModal} profile={selectedProfile} />
      </ProfileWrapper>
    );
  } else {
    return (
      <Section {...handlers}>
        <Arrow direction="left" onClick={handlePrev}>◀</Arrow>
        <CarouselContainer>
          <CarouselWrapper translateX={translateX}>
            {profiles.map((profile) => (
              <Card profile={profile} onClick={handleCardClick}/>
            ))}
          </CarouselWrapper>
        </CarouselContainer>
        <Arrow direction="right" onClick={handleNext}>▶</Arrow>
        <Modal show={selectedProfile !== null} onClose={closeModal} profile={selectedProfile} />
      </Section>
    );
  }
}

export default CarouselPrivate;
