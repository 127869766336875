import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import phoneButton from '../components/phoneButton';
import CarouselPrivate from '../components/CarouselPrivate';

const PageContainer = styled.div`
  background-color: ${theme.thirdColor};
  width: 100%;
  padding-bottom: 30px;
  padding-top: 50px;

  @media (max-width: 600px) {
    padding-top: 20px;

    p{
    font-size: 13px;
    }
  }

`;

const Container = styled.div`
  padding: 20px 40px;
  padding-bottom: 50px;
  background-color: ${theme.secondColor};
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 90%;
    padding: 10px 20px;
    padding-bottom: 30px;
  }
`;


const Container2 = styled.div`
  padding: 30px 5%;
  padding-bottom: 50px;
  background-color: ${theme.secondColor};
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 90%;
    padding: 5% 20%;
    padding-bottom :10%;
    
  }
`;


const Content = styled.p`
  color: ${theme.textColor};
  margin-bottom: 20px;
`;

const PhoneNumber = styled.span`
  color: #DFB01A;
  font-weight: bold;
`;

const ConsultationButton = styled(phoneButton)`
  margin-top: 20px;
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const ConsultationPrivate = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Helmet>
        <title>Consultation de Voyance Privée - Divinium</title>
        <meta name="description" content="Découvrez nos services de consultation de voyance privée. Nos médiums et voyants professionnels vous offrent des réponses précises et immédiates pour éclaircir votre avenir." />
        <meta name="keywords" content="consultation de voyance privée, médium, voyant, Divinium, consultation, voyance, avenir" />
      </Helmet>
      <Container>
        <Title>Consultation de Voyance Privée</Title>
        <Content>
          Appelez-nous au <a href={`tel:0189472174`} style={{ textDecoration: 'none' }}><PhoneNumber>01 89 47 21 74</PhoneNumber></a> pour une consultation de voyance privée exceptionnelle.
          Nos médiums et voyants, dotés de talents uniques, sont à votre disposition pour vous offrir des réponses précises et immédiates.
        </Content>
        <Content>
          Vous bénéficierez d'un service de voyance immédiate, confidentielle et sans attente. Nos voyants vous aident à éclaircir votre avenir en répondant à vos questions de manière rapide et sécurisée.
        </Content>
        <Content>
          Dès votre appel, notre secrétaire vous guide vers le médium le plus approprié à vos besoins. Que ce soit pour des questions sur le plan sentimental, familial ou professionnel et financier, nous avons le spécialiste qu'il vous faut.
        </Content>
        <Content>
          Nous sommes disponibles tous les jours de l'année de 8h à minuit.
        </Content>
        <Content>
          Pour connaître la disponibilité de nos voyants en temps réel, consultez notre planning. Si votre voyant préféré est occupé, nous organiserons un rendez-vous à un moment qui vous convient. De plus, notre secrétaire peut vous rappeler à l'heure prévue pour votre consultation.
        </Content>
        <Content>
          Notre équipe de médiums comprend des spécialistes en clairvoyance, clairaudience, médiumnité pure, ainsi que des experts en astrologie, numérologie et tarot. Nous sommes là pour vous garantir une consultation de qualité supérieure.
        </Content>
        <Content>
          Nos tarifs sont de 15 € pour les 10 premières minutes, puis de 3 € à 5 € par minute supplémentaire selon le voyant.
        </Content>
        <Content>
          Le paiement par carte bancaire est sécurisé : lorsque vous saisissez votre cryptogramme via votre clavier téléphonique, vos données sont cryptées automatiquement.
        </Content>
        <Content>
          Rejoignez-nous pour une expérience de voyance hors du commun, avec des réponses claires et un accompagnement personnalisé pour mieux comprendre votre avenir.
        </Content>
        <ConsultationButton href={`tel:0189472174`} style={{ textDecoration: 'none' }}>Contactez-nous</ConsultationButton>
      </Container>
      <Container2>
        <Title style={{textAlign: "center", marginBottom: 30, marginTop: 10}}>Nos voyants disponibles en Privé</Title>
        <CarouselPrivate/>
      </Container2>
    </PageContainer>
  );
};

export default ConsultationPrivate;
