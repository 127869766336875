import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { theme } from '../theme/theme';
import { collection, getDocs } from 'firebase/firestore';
import { StyledInput, StyledSelect } from '../components/StyledInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/Modal';
import Card from '../components/Card';
import { Helmet } from 'react-helmet-async';

const PageContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  padding-bottom: 50px;
`;

const FilterContainer = styled.div`
  margin-top: 10px;
  border-radius: 5px;
  background-color: #353639;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 60%;
  position: relative;

  @media (max-width: 1024px) {
    margin: 10px 5%;
    width: 90%;
  }
`;

const FilterTitle = styled.label`
  font-size: 16px;
  color: #C4C4C4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledSelectContainer2 = styled.div`
  display: none;

  @media (max-width: 1024px) {
    gap: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const ProfilesContainer = styled.div`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1024px) {
    width: 90%;
    gap: 20px 0px;
  }

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  flex: 0 0 calc(100% / 5 - 20px);

  @media (max-width: 1024px) {
    flex: 0 0 calc(100% / 3 - 20px);
  }

  @media (max-width: 600px) {
    flex: 0 0 calc(50% - 5px);
  }
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const NosVoyants = () => {
  const [profiles, setProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [specialityFilter, setSpecialityFilter] = useState('Toutes');
  const [statusFilter, setStatusFilter] = useState('Tous');
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      const querySnapshot = await getDocs(collection(db, 'Voyants'));
      const profilesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        pseudo: doc.data().pseudo,
        avatarUrl: doc.data().avatarUrl,
        status: doc.data().status,
        description: doc.data().description,
        specialities: doc.data().specialities,
        prixPremieres10Min: doc.data().prixPremieres10Min,
        prixParMinuteSup: doc.data().prixParMinuteSup,
        telephone: doc.data().telephone,
      }));
      setProfiles(profilesData);
    };

    fetchProfiles();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSpecialityChange = (e) => {
    setSpecialityFilter(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleCardClick = (profile) => {
    setSelectedProfile(profile);
  };

  const closeModal = () => {
    setSelectedProfile(null);
  };

  const filteredProfiles = profiles.filter(profile =>
    (profile.pseudo && profile.pseudo.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (specialityFilter === 'Toutes' || (profile.specialities && profile.specialities.includes(specialityFilter))) &&
    (statusFilter === 'Tous' || profile.status === statusFilter)
  );

  const uniqueSpecialities = Array.from(new Set(profiles.map(profile => profile.specialities).flat()));

  return (
    <PageContainer>
      <Helmet>
        <title>Voyants chez DIVINIUM - Rencontrez nos experts | NomDuSite</title>
        <meta name="description" content="Découvrez les voyants disponibles chez DIVINIUM. Rencontrez nos experts, consultez leur profil, leur spécialité et réservez une consultation."></meta>
        <meta name="keywords" content="voyants, experts, divinum, spécialistes, consultations, voyance, médiums, tarologues, astrologues, esotérisme"></meta>
      </Helmet>
      <Title>Les Voyants chez DIVINIUM</Title>
      <FilterContainer>
      
        <FilterTitle>Nos {profiles.length} Voyants</FilterTitle>
        <StyledSelectContainer>
          <StyledSelect style={{ backgroundColor: "#353639", border: "0px", boxShadow: "none" }} value={specialityFilter} onChange={handleSpecialityChange}>
            <option value="Toutes">Spécialité (tout)</option>
            {uniqueSpecialities.map(speciality => (
              <option key={speciality} value={speciality}>{speciality}</option>
            ))}
          </StyledSelect>
          <StyledSelect style={{ backgroundColor: "#353639", border: "0px", boxShadow: "none" }} value={statusFilter} onChange={handleStatusChange}>
            <option value="Tous">Status (tous)</option>
            <option value="Disponible">Disponible</option>
            <option value="En communication">En communication</option>
            <option value="Prendre rendez-vous">Prendre rendez-vous</option>
          </StyledSelect>
        </StyledSelectContainer>
        
        <div style={{ position: "relative" }}>
          <StyledInput
            style={{ paddingLeft: 35, backgroundColor: "#353639", border: "0px", boxShadow: "none" }}
            type="text"
            placeholder="Rechercher un voyant..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <FontAwesomeIcon style={{ fontSize: '15px', color: "#C4C4C4", position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)" }} icon={faSearch} />
        </div>
      </FilterContainer>

      <StyledSelectContainer2>
        <StyledSelect style={{ flex: 1, backgroundColor: "#353639", border: "0px", boxShadow: "none" }} value={specialityFilter} onChange={handleSpecialityChange}>
          <option value="Toutes">Spécialité (tout)</option>
          {uniqueSpecialities.map(speciality => (
            <option key={speciality} value={speciality}>{speciality}</option>
          ))}
        </StyledSelect>
        <StyledSelect style={{ flex: 1, backgroundColor: "#353639", border: "0px", boxShadow: "none" }} value={statusFilter} onChange={handleStatusChange}>
          <option value="Tous">Status (tous)</option>
          <option value="Disponible">Disponible</option>
          <option value="En communication">En communication</option>
          <option value="Prendre rendez-vous">Prendre rendez-vous</option>
        </StyledSelect>
      </StyledSelectContainer2>

      <ProfilesContainer>
        {filteredProfiles.map(profile => (
          <CardWrapper key={profile.id}>
            <Card profile={profile} onClick={handleCardClick} />
          </CardWrapper>
        ))}
      </ProfilesContainer>

      <Modal show={selectedProfile !== null} onClose={closeModal} profile={selectedProfile} />
    </PageContainer>
  );
};

export default NosVoyants;
