import React from 'react';
import styled from 'styled-components';
import ImageHome from '../assets/femmetel2.jpg';
import ImageWomen from '../assets/femme3.webp';
import VideoStars from '../assets/ciel3.mp4';
import ScrollingReviews from '../components/ScrollingReviews';
import CarouselAstro from '../components/CarouselAstro';
import CarouselPrivate from '../components/CarouselPrivate';
import CarouselAudiotel from '../components/CarouselAudiotel';
import { theme } from '../theme/theme';
import LatestArticles from '../components/LatestArticles';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';


const HomeContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const Section3 = styled.section`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const Section2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
  }
`;


const SectionHoroscope = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  width: 100%;
  overflow: hidden;
  border-top: 2px solid #E7C759;
  border-bottom: 2px solid #E7C759;
  position: relative;

  @media (max-width: 1024px) {
  }
`;

const Intro = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  padding: 0% 10%;

  @media (max-width: 1024px) {
    padding: 0% 2%;
    height: 400px;
  }
`;

const ImageHomeStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 1;
`;

const ImageWomenStyled = styled.img`
  position: absolute;
  bottom: 0;
  right: 150px;
  height: 100%;
  z-index: 10;

  @media (max-width: 768px) {
    right: -80px;
    display: none;
  }
`;

const IntroContent = styled.div`
  width: 60%;
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;

  @media (max-width: 768px) {
     width: 100%;
  }
`;

const CustomText = styled.p`
  font-family: 'Amsterdam Four', sans-serif;
  font-size: 32px;
  color: white;
  font-weight: 100;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #DFB01A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #B48E15;
  }
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const TitleLabel = styled.h1`
  z-index: 10;
  color: white;
  font-weight: 100;
  font-size: 34px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

const Separator = styled.span`
  width: 10%;
  height: 1px;
  background-color: #DFB01A;

  @media (max-width: 768px) {
    width: 40%;
  }
`;

const SubTitle = styled.h3`
  color: white;
  font-weight: 100;
  margin: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin: 20px 0px;
  }
`;

const Highlight = styled.span`
  color: #DFB01A;
  font-weight: 500;
`;

const VideoStyled = styled.video`
  position: absolute;
  top:0;
  bottom:0;
  width: 100%;
  height:100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.8;
`;


const Home = () => {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Voyance en Ligne - Divinium</title>
        <meta name="description" content="Découvrez Divinium, la plateforme de voyance en ligne. Nos médiums professionnels vous offrent des consultations privées et audiotel pour une guidance claire et authentique." />
        <meta name="keywords" content="voyance par téléphone, voyance en ligne, médium, consultation privée, audiotel, Divinium, médiums professionnels, guidance" />
      </Helmet>
      <HomeContainer>
        <Intro>
          <ImageHomeStyled src={ImageHome} alt="Background" />
          <ImageWomenStyled src={ImageWomen} alt="Women" />
          <IntroContent>
            <h1 style={{ color: "white", fontWeight: "100" }}>Bienvenue sur DIVINIUM</h1>
            <p>Divinium offre une plateforme de voyance authentique pour clarté et guidance avec des consultations personnalisées. Accédez à des médiums et voyants expérimentés pour vos questionnements et choix de vie.</p>
            <Button onClick={() => navigate("/about")}>En savoir plus</Button>
          </IntroContent>
        </Intro>
        <ScrollingReviews />
        <Section>
          <Title>Nos Voyants en Privé</Title>
          <Separator />
          <SubTitle>Disponibles en privé au <a href={`tel:0189472174`} style={{ textDecoration: 'none' }}><Highlight>01 89 47 21 74</Highlight></a></SubTitle>
          <CarouselPrivate />
        </Section>
        <Section>
          <Title>Nos Voyants en Audiotel</Title>
          <Separator />
          <SubTitle>Disponibles en Audiotel au <a href={`tel:0895795932`} style={{ textDecoration: 'none' }}><Highlight>08 95 79 59 32</Highlight></a></SubTitle>
          <CarouselAudiotel />
        </Section>
        <SectionHoroscope>
          <VideoStyled autoPlay loop muted playsInline>
            <source src={VideoStars} type="video/mp4" />
            Votre navigateur ne supporte pas les vidéos HTML5.
          </VideoStyled>
          <TitleLabel>L'Horoscope du jour</TitleLabel>
          <Separator />
          <CarouselAstro />
        </SectionHoroscope>

        <Section2>
          <div style={{ width: "60%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Title>l'Actualité Divinium</Title>
            <Separator />
          </div>
          <LatestArticles />
        </Section2>
        <Section2>
          <CustomText>"Depuis la création de Divinium, notre engagement a toujours été de fournir des services de voyance sérieux et authentiques. Chaque jour, notre équipe s'efforce de vous offrir des consultations personnalisées et de haute qualité pour vous guider à travers les défis de la vie. Nous sommes honorés de faire partie de votre parcours spirituel."</CustomText>
          <CustomText>Yara</CustomText>
        </Section2>
      </HomeContainer>
    </>
  );
};

export default Home;
