import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartSummary from '../components/CartSummary';
import PaymentMethod from '../components/PaymentMethod';
import PackList from '../components/PackList';

const packs = [
  {
    name: "START",
    minutes: "10 MINUTES",
    description: "téléphone ou tchat",
    price: "20,90 €"
  },
  {
    name: "STANDARD",
    minutes: "15 MINUTES",
    description: "téléphone ou tchat ou 1 CONSULTATION par mail",
    price: "29,50 €"
  },
  {
    name: "CONFORT",
    minutes: "30 MINUTES",
    description: "téléphone ou tchat ou 2 CONSULTATIONS par mail",
    price: "57,50 €"
  },
  {
    name: "SÉRÉNITÉ",
    minutes: "60 MINUTES",
    description: "téléphone ou tchat ou 4 CONSULTATIONS par mail",
    price: "107,50 €"
  },
  {
    name: "HAPPINESS",
    minutes: "90 MINUTES",
    description: "téléphone ou tchat ou 6 CONSULTATIONS par mail",
    price: "157,50 €"
  },
  {
    name: "FEELGOOD",
    minutes: "120 MINUTES",
    description: "téléphone ou tchat ou 8 CONSULTATIONS par mail",
    price: "197,50 €"
  },
];

const PurchasePack = () => {
  const [selectedPack, setSelectedPack] = useState(null);
  const [step, setStep] = useState(1); // New state to manage steps
  const navigate = useNavigate();

  const handlePackSelect = (pack) => {
    setSelectedPack(pack);
    setStep(2); // Move to the next step
  };

  const handleModifyPack = () => {
    setSelectedPack(null);
    setStep(1); // Go back to the pack selection step
  };

  const handlePaymentMethodSelect = (method) => {
    console.log('Selected payment method:', method);
    // Proceed to payment step or final confirmation
    // For now, just log the selected method
    navigate('/payment', { state: { selectedPack, method } });
  };

  // New function to handle cart validation
  const handleCartValidation = () => {
    setStep(3); // Move to the payment method selection step
  };

  return (
    <div style={styles.container}>
      <h1>Choisissez parmi nos packs</h1>
      <p>Avec Spiriteo, vous n’avez jamais de mauvaises surprises</p>
      <ol style={styles.steps}>
        <li onClick={handleModifyPack} style={step === 1 ? styles.activeStep : {}}>1 - Choix du pack</li>
        <li onClick={() => setStep(2)} style={step === 2 ? styles.activeStep : {}}>2 - Panier</li>
        <li style={step === 3 ? styles.activeStep : {}}>3 - Paiement</li>
      </ol>
      <p style={styles.plus}>LES + SPIRITEO &gt; Aucune attente / De vrais voyants 7j/7-24h/24 / 99% de clients satisfaits de leurs consultations !</p>
      
      {step === 1 && (
        <PackList onPackSelect={handlePackSelect} />
      )}
      
      {step === 2 && selectedPack && (
        <>
          <CartSummary selectedPack={selectedPack} onModifyPack={handleModifyPack} onValidateCart={handleCartValidation} />
        </>
      )}

      {step === 3 && selectedPack && (
        <PaymentMethod onPaymentMethodSelect={handlePaymentMethodSelect} />
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '80%', // Largeur de 80% de la page
    margin: '0 auto', // Centrer les éléments horizontalement
    textAlign: 'center',
    // padding: '20px',
  },
  steps: {
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  activeStep: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  plus: {
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
  },
  packContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  packCard: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    width: '200px',
    textAlign: 'center',
  },
};

export default PurchasePack;