import React, { useState } from 'react';

const CartSummary = ({ selectedPack, onModifyPack, onValidateCart }) => {
  const [codeOffre, setCodeOffre] = useState('');
  const [totalRemise, setTotalRemise] = useState(0);

  const handleCodeOffreChange = (e) => {
    setCodeOffre(e.target.value);
  };

  const handleApplyCodeOffre = () => {
    // Simulate applying a discount code
    if (codeOffre === 'OFFRE10') {
      setTotalRemise(10);
    } else {
      setTotalRemise(0);
    }
  };

  return (
    <div style={styles.container}>
      <h2>PACK {selectedPack.name}</h2>
      <p>{selectedPack.minutes} téléphone ou tchat</p>
      <p>{selectedPack.price} <button onClick={onModifyPack}>Modifier le pack</button></p>
      <div style={styles.offerSection}>
        <p>Vous bénéficiez d'un code « Offre Spéciale » ?</p>
        <input type="text" value={codeOffre} onChange={handleCodeOffreChange} placeholder="CODE OFFRE" />
        <button onClick={handleApplyCodeOffre}>OK</button>
      </div>
      <div style={styles.totalSection}>
        <p>TOTAL REMISE</p>
        <p>{totalRemise} €</p>
      </div>
      <div style={styles.totalSection}>
        <p>TOTAL À PAYER</p>
        <p>{parseFloat(selectedPack.price.replace('€', '').replace(',', '.')) - totalRemise} €</p>
      </div>
      <button onClick={onValidateCart} style={styles.validateButton}>VALIDEZ VOTRE PANIER</button>
    </div>
  );
};

const styles = {
  container: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  offerSection: {
    marginTop: '20px',
  },
  totalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  validateButton: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default CartSummary;