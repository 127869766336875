import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 20px 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterNav = styled.nav`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    flex-direction: column;
    text-align: center;
  }
`;

const FooterNavItem = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-right: 20px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Copyright = styled.p`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterNav>
          <FooterNavItem to="/">Accueil</FooterNavItem>
          <FooterNavItem to="/consultationprivate">Consultations privées</FooterNavItem>
          <FooterNavItem to="/consultationaudio">Consultation audiotel</FooterNavItem>
          <FooterNavItem to="/nosvoyants">Nos voyants</FooterNavItem>
          <FooterNavItem to="/horoscope">Horoscopes</FooterNavItem>
          <FooterNavItem to="/about">À propos</FooterNavItem>
          <FooterNavItem to="/contact">Contact</FooterNavItem>
          <FooterNavItem to="/all-articles">Actualités</FooterNavItem>
          <FooterNavItem to="/guestbook">Livre d'or</FooterNavItem>
        </FooterNav>
        <Copyright>&copy; 2016-{new Date().getFullYear()} Divinium</Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
