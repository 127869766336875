import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { theme } from '../theme/theme';

const DetailContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Content = styled.div`
  color: white;
  font-size: 18px;
  max-width: 800px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const HoroscopeDetail = () => {
  const { period } = useParams();

  const horoscopeData = {
    jour: "Contenu de l'horoscope du jour...",
    demain: "Contenu de l'horoscope de demain...",
    semaine: "Contenu de l'horoscope de la semaine...",
    mois: "Contenu de l'horoscope du mois...",
    annee: "Contenu de l'horoscope de l'année...",
  };

  return (
    <DetailContainer>
      <Title>Horoscope {period}</Title>
      <Content>{horoscopeData[period]}</Content>
    </DetailContainer>
  );
};

export default HoroscopeDetail;
