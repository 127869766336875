import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAQ5-Yd06cpNlYeul_uWH0ZvJs3tRaidro",
    authDomain: "voyance-516c2.firebaseapp.com",
    projectId: "voyance-516c2",
    storageBucket: "voyance-516c2.appspot.com",
    messagingSenderId: "512307354437",
    appId: "1:512307354437:web:e8c6413d1b42fcf3377c2c"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, db, storage, auth };