import React, { useState } from 'react';
import styled from 'styled-components';
import { collection, addDoc } from 'firebase/firestore'; // Importez les fonctions de Firestore de Firebase
import { db } from '../firebase'; // Importez votre instance Firebase
import { theme } from '../theme/theme';
import { StyledInput, StyledSelect, StyledTextarea } from '../components/StyledInput'; 
import { Helmet } from 'react-helmet-async';

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.thirdColor};
    width: 100%;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
`;

const FormContainer = styled.div`
    background-color: ${theme.secondColor};
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 600px;
`;

const Title = styled.h2`
    color: white;
    text-align: center;
    margin-bottom: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormLabel = styled.label`
    color: white;
    margin-bottom: 10px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
`;

const Option = styled.option`
    color: ${theme.textColor};
`;

const Button = styled.button`
    margin-top: 30px;
    background-color: ${theme.primaryColor};
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${theme.primaryHover};
    }
`;

const SuccessMessage = styled.div`
    background-color: ${theme.primaryColor};
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 18px;
`;

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false); // Nouvel état pour le message de succès

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'tickets'), { // Utilisez les fonctions de Firestore pour ajouter un document à la collection 'tickets'
                name: formData.name,
                email: formData.email,
                subject: formData.subject,
                message: formData.message,
                created_at: new Date()
            });

            // Réinitialiser le formulaire après l'envoi
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            setIsSubmitted(true); // Définir isSubmitted sur true après l'envoi réussi
            console.log('Ticket créé avec succès.');
        } catch (error) {
            console.error('Erreur lors de la création du ticket: ', error);
        }
    };

    return (
        <>
        <Helmet>
            <title>Contactez-nous - Divinium</title>
            <meta name="description" content="Contactez-nous pour toute question générale, assistance technique, demande de partenariat ou autre. Remplissez le formulaire ci-dessous pour nous envoyer un message." />
            <meta name="keywords" content="contact, formulaire de contact, message, assistance, partenariat, Divinium" />
        </Helmet>
        <ContactContainer>
            {isSubmitted ? ( // Condition pour afficher le message de succès si le formulaire est soumis
                <SuccessMessage>Votre message a été envoyé avec succès!</SuccessMessage>
            ) : (
                <FormContainer>
                    <Title>Contactez-nous</Title>
                    <Form onSubmit={handleSubmit}>
                        <FormLabel htmlFor="name">Nom</FormLabel>
                        <StyledInput
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />

                        <FormLabel htmlFor="email">Email</FormLabel>
                        <StyledInput
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />

                        <FormLabel htmlFor="subject">Sujet</FormLabel>
                        <StyledSelect
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        >
                            <Option value="">-- Sélectionner un sujet --</Option>
                            <Option value="Question générale">Question générale</Option>
                            <Option value="Assistance technique">Assistance technique</Option>
                            <Option value="Demande de partenariat">Demande de partenariat</Option>
                            <Option value="Autre">Autre</Option>
                        </StyledSelect>

                        <FormLabel htmlFor="message">Message</FormLabel>
                        <StyledTextarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />

                        <Button type="submit">Envoyer</Button>
                    </Form>
                </FormContainer>
            )}
        </ContactContainer>
        </>
    );
};

export default Contact;
