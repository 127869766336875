import React, { useState } from 'react';
import styled from 'styled-components';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { theme } from '../theme/theme';
import { StyledInput, StyledTextarea } from '../components/StyledInput'; // Importez vos composants stylisés
import StyledButton from '../components/StyledButton'; 
import LogoImage from '../assets/divinium.png'; 
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledCheckbox from '../components/StyledCheckbox'; // Importez le composant StyledCheckbox

const LogoImageStyled = styled.img`
  width: 90%;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 10%;
  }
`;

const BackOpacity = styled.div`
  position: absolute;
  z-index: 1001;
  top : 0;
  bottom : 0;
  left: 0;
  right: 0;
  background-color : rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RegisterPage = styled.div`
  margin: 20% 20%;
  position: absolute;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 5% 5%;
  }
`;

const RegisterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #353639;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  align-content: center;
  text-align: center;
  flex: 1;
  padding: 20px;
  background-color: ${theme.mainColor};
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */
`;

const RightContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: #C4C4C4;

  @media (max-width: 1024px) {
    font-size: 11px;
  }
`;

const CloseButton = styled.a`
  
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    password: '',
    country: '',
    agreeTerms: false,
    receiveOffers: false
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des données
    const { firstName, email, password, country, agreeTerms } = formData;
    if (!firstName || !email || !password || !country || !agreeTerms || !isValidEmail(email)) {
      alert('Veuillez remplir tous les champs correctement.');
      return;
    }

    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Mise à jour du profil utilisateur avec le prénom
      await updateProfile(user, { displayName: firstName });

      // Envoyer un email de vérification
      await sendEmailVerification(user);
      console.log('Email de vérification envoyé!');

      // Informer l'utilisateur de vérifier son email
      alert('Un email de vérification a été envoyé. Veuillez vérifier votre boîte de réception avant de vous connecter.');

      // Rediriger vers la page de connexion ou une autre page appropriée
      navigate('/welcome');
    } catch (error) {
      setError(error.message);
      console.error('Erreur d\'inscription:', error);
    }
  };

  const isValidEmail = (email) => {
    // Validation de l'email
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <BackOpacity>
      <RegisterPage>
        <RegisterContainer>
          <LeftContent>
            <LogoImageStyled src={LogoImage} alt="Logo" />
          </LeftContent>
          <RightContent>
            <CloseButton href="/" style={{ color: "#C4C4C4", display: "flex", alignItems: "center", fontWeight: "500", textDecoration :"none"}}>
              <FontAwesomeIcon style={{ fontSize: '20px', color: "#C4C4C4", marginRight: 10 }} icon={faChevronCircleLeft} /> Retour
            </CloseButton>
            <Title style={{color: "#C4C4C4", marginTop: 10, textAlign:"left"}}>Inscription</Title>
            <Form onSubmit={handleSubmit}>
              <Label htmlFor="firstName">Prénom ou Pseudo:</Label>
              <StyledInput type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />

              <Label htmlFor="email">Email:</Label>
              <StyledInput type="email" id="email" name="email" value={formData.email} onChange={handleChange} />

              <Label htmlFor="password">Mot de passe (8 caractères minimum):</Label>
              <StyledInput type="password" id="password" name="password" value={formData.password} onChange={handleChange} minLength="8" />

              <Label htmlFor="country">Pays:</Label>
              <StyledInput as="select" id="country" name="country" value={formData.country} onChange={handleChange}>
                <option value="">Sélectionner un pays</option>
                <option value="france">France</option>
                <option value="belgique">Belgique</option>
                <option value="suisse">Suisse</option>
                {/* Ajoutez d'autres pays francophones ici */}
              </StyledInput>
              <br />
              <Label htmlFor="agreeTerms">
                <StyledCheckbox type="checkbox" id="agreeTerms" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} />
                J'ai lu et j'approuve sans réserve les conditions générales d'utilisation
              </Label>

              <Label htmlFor="receiveOffers">
                <StyledCheckbox type="checkbox" id="receiveOffers" name="receiveOffers" checked={formData.receiveOffers} onChange={handleChange} />
                Je souhaite recevoir les offres exclusives de Omega Voyance
              </Label>

              <StyledButton onClick={handleSubmit}>S'inscrire</StyledButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Form>
            <p>Vous avez déjà un compte ? <a style={{color: "#B69323"}} href="/login">Connexion</a></p>
          </RightContent>
        </RegisterContainer>
      </RegisterPage>
    </BackOpacity>
  );
}

export default Register;
