import React from 'react';

const PackList = ({ onPackSelect }) => {
  const packsData = [
    {
      name: "START",
      minutes: "10 mn",
      description: "téléphone ou tchat",
      normalprice: "31,90 €",
      price: "55,00 €",
      priceminute: "5,50 €",
    },
    {
      name: "STANDARD",
      minutes: "25 mn",
      description: "téléphone ou tchat ou 1 CONSULTATION par mail",
      normalprice: "53,90 €",
      price: "35,00 €",
      priceminute: "5,50 €",
    },
    {
      name: "CONFORT",
      minutes: "50 mn",
      description: "téléphone ou tchat ou 2 CONSULTATIONS par mail",
      price: "57,50 €",
      normalprice: "107,70 €",
      price: "70,00 €",
      priceminute: "5,00 €",
    },
    {
      name: "SÉRÉNITÉ",
      minutes: "100 mn",
      description: "téléphone ou tchat ou 4 CONSULTATIONS par mail",
      price: "215,40 €",
      normalprice: "35,90 €",
      price: "135,00 €",
      priceminute: "4,50 €",
    },
    {
      name: "HAPPINESS",
      minutes: "200 mn",
      description: "téléphone ou tchat ou 6 CONSULTATIONS par mail",
      price: "323,10 €",
      normalprice: "35,90 €",
      price: "190,00 €",
      priceminute: "4,30 €",
    },
    {
      name: "FEELGOOD",
      minutes: "300 mn",
      description: "téléphone ou tchat ou 8 CONSULTATIONS par mail",
      price: "430,80 €",
      normalprice: "35,90 €",
      price: "25,00 €",
      priceminute: "3,90 €",
    },
  ];

  return (
    <div style={styles.packContainer}>
      {packsData.map((pack) => (
        <div key={pack.name} style={styles.packCard} onClick={() => onPackSelect(pack)}>
          <h2>{pack.name}</h2>
          <p>{pack.minutes}</p>
          <p>{pack.description}</p>
          <p>{pack.price}</p>
          <button>Choisir</button>
        </div>
      ))}
    </div>
  );
};

const styles = {
  packContainer: {
    display: 'flex',
    flexWrap: 'nowrap', // Empêche le contenu de se plier sur plusieurs lignes
    justifyContent: 'center',
    overflowX: 'auto', // Ajoute une barre de défilement horizontale si nécessaire
  },
  packCard: {
    flex:1,
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px',
    textAlign: 'center',
  },
};

export default PackList;
