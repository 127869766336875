import React, { useState } from 'react';
import styled from 'styled-components';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { theme } from '../theme/theme';
import { StyledInput, StyledTextarea } from '../components/StyledInput'; // Importez vos composants stylisés
import StyledButton from '../components/StyledButton'; 
import LogoImage from '../assets/divinium.png'; 
import { faClose, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LogoImageStyled = styled.img`
  width: 90%;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 10%;
  }
`;

const BackOpacity = styled.div`
position: absolute;
z-index: 1001;
top : 0;
bottom : 0;
left: 0;
right: 0;
background-color : rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginPage = styled.div`
margin: 20% 20%;
position: absolute;
z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 5% 5%;
  }
`;

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #353639;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
align-content: center;
  text-align: center;
  flex: 1;
  padding: 20px;
  background-color: ${theme.mainColor};
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */

`;

const RightContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow: hidden;

`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    font-size: 11px;
  }
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 16px;
`;

const CloseButton = styled.a`
  
`;

const ForgotPasswordLink = styled.a`
  margin-top: 10px;
  text-decoration: none;
  color: #007bff;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirection après une connexion réussie
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  }; 

  return (
    <BackOpacity>
    <LoginPage>
      <LoginContainer>
        <LeftContent>
          <LogoImageStyled src={LogoImage} alt="Logo" />
        </LeftContent>
        <RightContent>
          <CloseButton href="/" style={{ color: "#C4C4C4", display: "flex", alignItems: "center", fontWeight: "500", textDecoration :"none"}}>
            <FontAwesomeIcon style={{ fontSize: '20px', color: "#C4C4C4", marginRight: 10 }} icon={faChevronCircleLeft} /> Retour
          </CloseButton>
          <Title style={{color: "#C4C4C4", marginTop: 40, textAlign:"left"}}>Connexion</Title>
          <Form onSubmit={handleSubmit}>
            <Label style={{color: "#C4C4C4"}} htmlFor="email">Email:</Label>
            <StyledInput
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <Label style={{color: "#C4C4C4"}} htmlFor="password">Mot de passe:</Label>
            <StyledInput
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <StyledButton onClick={handleSubmit}>Se connecter</StyledButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Form>
          <ForgotPasswordLink href="/forgot-password" style={{color: "#B69323"}}>Mot de passe oublié ?</ForgotPasswordLink>
          <p >Vous n'avez pas de compte ? <a style={{color: "#B69323"}} href="/register">Inscription</a></p>
        </RightContent>
      </LoginContainer>
    </LoginPage>
    </BackOpacity>
  );
}

export default Login;
