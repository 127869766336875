import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cheerio from 'cheerio';
import { theme } from '../theme/theme';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const HoroscopeContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Content = styled.div`
  color: white;
  font-size: 18px;
  max-width: 800px;
  text-align: left;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ZodiacImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: black;
  padding: 10px;
  margin: 20px 0;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  background-color: #DFB01A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #B48E15;
  }
`;

const HoroscopePage = ({ selectedSign, selectedPeriod, handleBackClick, signImage, signPath }) => {
  const [horoscopeTitle, setHoroscopeTitle] = useState('');
  const [horoscope, setHoroscope] = useState({});
  const [error, setError] = useState(null);

  const fetchDataAndStoreInFirestore = async () => {
    try {
      const lowerCaseSignPath = signPath.toLowerCase();
      // console.log(lowerCaseSignPath);
      // console.log("sign", lowerCaseSignPath);
      const response = await fetch(`https://autumn-snowflake-bded.divinium-bc8.workers.dev/${lowerCaseSignPath}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/plain')) {
        const html = await response.text();
        const $ = cheerio.load(html);
        const mainTitle = $('h1').text();
        setHoroscopeTitle(mainTitle);
        // console.log('Main title:', mainTitle);

        const horoscopeData = {};
        $('.c-horoscope-topic-card').each((index, element) => {
          const title = $(element).find('h3').text();
          const description = $(element).find('p').text();
          horoscopeData[title] = description;
        });

        setHoroscope(horoscopeData);
        await storeDataInFirestore(mainTitle, horoscopeData);
      } else {
        throw new Error('Unexpected content type');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const storeDataInFirestore = async (mainTitle, horoscopeData) => {
    try {
      const timestamp = new Date().getTime();
      const userHoroscopeDocRef = doc(db, `horoscopes/${selectedSign}`);
      await setDoc(userHoroscopeDocRef, {
        mainTitle,
        horoscopeData,
        lastUpdate: timestamp,
        update: timestamp,
      });
    } catch (error) {
      console.error('Error storing data in Firestore:', error);
    }
  };

  const fetchDataFromFirestore = async () => {
    try {
      const userHoroscopeDocRef = doc(db, `horoscopes/${selectedSign}`);
      const docSnap = await getDoc(userHoroscopeDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const lastUpdate = data.lastUpdate;
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - lastUpdate;
        const shouldFetchFreshData = timeDifference >= (4 * 60 * 60 * 1000); // 4 hours in milliseconds
        if (shouldFetchFreshData) {
          await fetchDataAndStoreInFirestore();
        } else {
          setHoroscopeTitle(data.mainTitle);
          setHoroscope(data.horoscopeData);
        }
      } else {
        await fetchDataAndStoreInFirestore();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchDataFromFirestore();
  }, [signPath]);

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <HoroscopeContainer>
      <ZodiacImage src={signImage} alt={selectedSign} />
      <Content>
        <h4>{horoscopeTitle}</h4>
        {horoscope && Object.entries(horoscope).map(([title, description]) => (
          <div key={title}>
            <h6>{title}</h6>
            <p>{description}</p>
          </div>
        ))}
      </Content>
      <BackButton onClick={handleBackClick}>Retour</BackButton>
    </HoroscopeContainer>
  );
};

export default HoroscopePage;
