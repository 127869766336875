import React from 'react';
import ServiceCard from '../components/ServiceCard';

function Services() {
  const services = [
    { title: 'Tarot', description: 'Consultation de tarot personnalisée.' },
    { title: 'Astrologie', description: 'Analyse complète de votre thème astral.' },
    // Ajoute plus de services ici
  ];

  return (
    <div>
      <h2>Nos Services</h2>
      <div>
        {services.map((service, index) => (
          <ServiceCard key={index} title={service.title} description={service.description} />
        ))}
      </div>
    </div>
  );
}

export default Services;