import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import articlesData from '../articlesData'; // Import des données des articles
import { theme } from '../theme/theme';
import StyledButton from './StyledButton';

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

`;

const ArticleCard = styled.div`
  background-color: ${theme.containerColor};
  text-align: left;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(33.3333% - 20px);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const ArticleTitle = styled.h3`
  margin-top: 5px;
  color: ${theme.textColor};
`;

const ArticleContent = styled.p`
  color: ${theme.textColor};
`;

const ArticleDate = styled.p`
  color: gray;
  font-size: 12px;
`;

const ITEMS_PER_ROW = 3;

const LatestArticles = () => {
  const navigate = useNavigate();
  const latestArticles = articlesData.slice(0, 3);

  return (
    <Section>
      {latestArticles.map((article, index) => (
        <ArticleCard key={article.id}>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>{article.date}</ArticleDate>
          <ArticleContent>{article.content.substring(0, 100)}...</ArticleContent>
          <Link style={{textDecoration: "none", color: "#DFB01A"}} to={`/article/${article.id}`}>Lire la suite...</Link>
        </ArticleCard>
      ))}
      <StyledButton><Link style={{textDecoration: "none", color: "white"}} to={'/all-articles'}>Voir tous les articles</Link></StyledButton>
    </Section>
  );
};

export default LatestArticles;
