import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { theme } from '../theme/theme';
import StyledButton from '../components/StyledButton';
import { Helmet } from 'react-helmet-async';

const PageContainer = styled.div`
  background-color: ${theme.thirdColor};
  width: 100%;
  padding-bottom: 30px;
  padding-top: 50px;

  @media (max-width: 600px) {
    padding-top: 20px;

    p {
      font-size: 13px;
    }
  }
`;

const Container = styled.div`
  padding: 20px 40px;
  padding-bottom: 50px;
  background-color: ${theme.secondColor};
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 90%;
    padding: 10px 20px;
    padding-bottom: 30px;
  }
`;

const Content = styled.p`
  color: ${theme.textColor};
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const About = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Helmet>
        <title>Bienvenue chez Divinium - Votre plateforme de voyance | Divinium</title>
        <meta name="description" content="Bienvenue chez Divinium, votre plateforme de voyance dédiée à vous apporter clarté et guidance à travers nos consultations privées et audiotel. Nous sommes déterminés à vous offrir une expérience de voyance personnalisée et de haute qualité, directement depuis le confort de votre domicile."></meta>
        <meta name="keywords" content="Divinium, voyance, plateforme de voyance, consultation privée, consultation audiotel, médiums, voyants, clairvoyance, clairaudience, astrologie, numérologie, tarot, spécialistes de la voyance, avenir, guidance, qualité de service, sécurité, confidentialité"></meta>
      </Helmet>
      <Container>
        <Title>Bienvenue chez Divinium</Title>
        <Content>
          Bienvenue chez Divinium, votre plateforme de voyance dédiée à vous apporter clarté et guidance à travers nos consultations privées et audiotel. Nous sommes déterminés à vous offrir une expérience de voyance personnalisée et de haute qualité, directement depuis le confort de votre domicile.
        </Content>
        <Content>
          Chez Divinium, nous mettons à votre disposition une plateforme de voyance authentique, dédiée à vous offrir clarté et guidance à travers des consultations personnalisées. Notre mission est de vous accompagner avec sérieux et bienveillance dans vos questionnements et vos choix de vie, en vous offrant un accès direct à des médiums et voyants expérimentés.
        </Content>
        <Title>Pourquoi Choisir Divinium ?</Title>
        <Content>
          Divinium se distingue par son équipe de médiums et voyants sélectionnés pour leur expertise dans la clairvoyance, la clairaudience, l'astrologie, la numérologie, le tarot et bien plus encore. Chacun de nos praticiens est choisi pour son sérieux, son écoute attentive et son engagement à vous guider à travers les défis de la vie avec sérénité.
        </Content>
        <Content>
          Chez Divinium, nous sélectionnons rigoureusement nos médiums et voyants pour leur expertise et leur authenticité. Chaque praticien est reconnu pour ses compétences en clairvoyance, clairaudience, astrologie, numérologie, tarot et bien plus encore. Nous nous engageons à vous offrir une consultation sérieuse et respectueuse, afin de vous guider avec précision et bienveillance.
        </Content>
        <Title>Nos Services</Title>
        <Content>
          <ul>
            <li>Consultation Privée: Profitez d'une consultation privée avec un médium spécialisé pour des réponses approfondies et personnelles.</li>
            <li>Consultation Audiotel 24/7: Appelez-nous au 08 ** ** ** ** pour une consultation immédiate avec le médium de votre choix.</li>
            <li>Diversité de Médiums: Choisissez parmi une variété de spécialistes disponibles pour répondre à vos questions sur le plan sentimental, familial, professionnel, et bien d'autres.</li>
            <li>Sécurité et Confidentialité: Vos consultations sont sécurisées et confidentielles, garantissant un environnement de confiance pour explorer votre avenir.</li>
          </ul>
        </Content>
        <Title>Engagement Qualité</Title>
        <Content>
          Chez Divinium, la qualité de service est notre priorité absolue. Chaque médium travaille depuis la France, dans des conditions optimales pour vous offrir une voyance de qualité supérieure. Nous nous engageons à vous fournir des réponses précises et un soutien bienveillant pour vous guider vers un avenir plus clair.
        </Content>
        <Content>
          Chez Divinium, nous nous engageons à vous offrir une voyance de haute qualité, fondée sur l'écoute et l'accompagnement bienveillant. Nos médiums travaillent dans des conditions optimales, depuis la France, pour vous apporter des réponses précises et un soutien sincère dans chaque aspect de votre vie.
        </Content>
        <Title>Contactez-Nous</Title>
        <Content>
          Explorez notre site pour découvrir nos médiums, consultez notre planning en temps réel, et commencez votre consultation. Chez Divinium, nous sommes là pour vous accompagner à chaque étape de votre voyage spirituel.
        </Content>
        <Content>
          Explorez notre site pour découvrir nos médiums et consultez notre planning en temps réel. Chez Divinium, nous sommes là pour vous accompagner à chaque étape de votre parcours spirituel. Faites le premier pas vers un avenir plus clair avec Divinium.
        </Content>
        <StyledButton onClick={() => navigate('/contact')}>Contactez-nous</StyledButton>
      </Container>
    </PageContainer>
  );
};

export default About;
