import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import phoneButton from '../components/phoneButton';
import CarouselAudiotel from '../components/CarouselAudiotel';

const PageContainer = styled.div`
  background-color: ${theme.thirdColor};
  width: 100%;
  padding-bottom: 30px;
  padding-top: 50px;

  @media (max-width: 600px) {
    padding-top: 20px;

    p {
      font-size: 13px;
    }
  }
`;

const Container = styled.div`
  padding: 20px 40px;
  padding-bottom: 50px;
  background-color: ${theme.secondColor};
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 90%;
    padding: 10px 20px;
    padding-bottom: 30px;
  }
`;

const Container2 = styled.div`
  padding: 30px 5%;
  padding-bottom: 50px;
  background-color: ${theme.secondColor};
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    width: 90%;
    padding: 5% 20%;
    padding-bottom: 10%;
  }
`;

const Content = styled.p`
  color: ${theme.textColor};
  margin-bottom: 20px;
`;

const PhoneNumber = styled.span`
  color: #DFB01A;
  font-weight: bold;
`;

const ConsultationButton = styled(phoneButton)`
  margin-top: 20px;
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const ConsultationAudiotel = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Helmet>
        <title>Consultation de Voyance Audiotel (Sans CB) - Divinium</title>
        <meta name="description" content="Découvrez notre service de consultation de voyance audiotel sans carte bancaire. Nos médiums et voyants sont disponibles à tout moment pour vous offrir des réponses précises et immédiates." />
        <meta name="keywords" content="consultation de voyance audiotel, médium, voyant, Divinium, consultation, voyance, avenir, sans CB" />
      </Helmet>
      <Container>
        <Title>Consultation de Voyance Audiotel (Sans CB)</Title>
        <Content>
          La consultation en Audiotel se fait sans Carte Bancaire, il vous suffit d'appeler au <a href={`tel:0895795932`} style={{ textDecoration: 'none' }}><PhoneNumber>08 95 79 59 32</PhoneNumber></a> où nos médiums et voyants sont disponibles à tout moment, même le dimanche, 24h/24.
        </Content>
        <Content>
          Profitez de notre service de voyance audiotel pour une consultation directe et en ligne, où que vous soyez. Contactez-nous à votre convenance et choisissez parmi une variété de voyants et médiums expérimentés.
        </Content>
        <Content>
          Nos médiums sont spécialisés en flashs, médiumnité pure, clairvoyance, clairaudience, astrologie, numérologie, tarot et oracles. Chacun apporte sérieux, écoute attentive et une guidance adaptée pour éclairer votre chemin vers l'avenir.
        </Content>
        <Content>
          Pour consulter, appelez le <a href={`tel:0895795932`} style={{ textDecoration: 'none' }}><PhoneNumber>08 95 79 59 32</PhoneNumber></a> et suivez les instructions pour être mis en relation directe avec votre voyant. En cas d'indisponibilité, vous pouvez attendre ou choisir un autre médium disponible.
        </Content>
        <Content>
          Les consultations sont facturées à 80 centimes par minute, prélevés directement sur votre facture téléphonique. Conformément à la réglementation française, les appels sont limités à 30 minutes.
        </Content>
        <Content>
          Consultez notre planning pour découvrir nos disponibilités et laissez nos médiums vous offrir des réponses précises et un accompagnement bienveillant dans tous les aspects de votre vie.
        </Content>
        <ConsultationButton href={`tel:0895795932`} style={{ textDecoration: 'none' }}>Contactez-nous</ConsultationButton>
      </Container>
      <Container2>
        <Title style={{ textAlign: "center", marginBottom: 30, marginTop: 10 }}>Nos voyants disponibles en Audiotel</Title>
        <CarouselAudiotel />
      </Container2>
    </PageContainer>
  );
};

export default ConsultationAudiotel;
