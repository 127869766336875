import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const StyledInput = styled.input`
  background-color: #5A5B5E;
  border: 1px solid #5A5B5E;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  color: white;

  &:focus {
    outline: none;
    border: 1px solid  #C4A641;
    box-shadow: 0 0 10px #C4A641;
  }
`;

const StyledTextarea = styled.textarea`
  background-color: #5A5B5E;
  border: 1px solid #5A5B5E;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  color: white;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid  #C4A641;
    box-shadow: 0 0 10px #C4A641;
  }
`;

const StyledSelect = styled.select`
  background-color: #5A5B5E;
  border: 1px solid #5A5B5E;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
  color: #C4C4C4;

  &:focus {
    outline: none;
    border: 1px solid  #C4A641;
    box-shadow: 0 0 10px #C4A641;
  }
`;


export { StyledInput, StyledTextarea, StyledSelect };
