import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from '../firebase'; // Assurez-vous que le chemin d'importation est correct
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5% auto;
`;

const ResponsiveCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

const DayContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const DayColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100px;
  min-width: 120px;

  @media (max-width: 600px) {
    min-width: 100px;
  }
`;

const TimeSlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
`;

const TimeSlot = styled.div`
  // height: 40px;
  width: 25%;
  min-width: 10px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right-width: ${props => (props.index % 4 === 3 ? '2px' : '1px')};
  background-color: ${props => {
    if (props.color === 'green') {
      return '#DFB01A'; // jaune
    } else if (props.color === 'violet') {
      return '#8E1ADF'; // vert
    } else {
      return 'white';
    }
  }};
`;


const DayLabel = styled.div`
  width: 100%;
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TimeLabel = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
  const hours = Math.floor(i).toString().padStart(2, '0');
  return `${hours}h`;
});
const quarts = Array.from({ length: 4 }, (_, i) => {
  const quart = Math.floor(i).toString().padStart(2, '0');
  return `00:${quart}`;
});

const WeeklyCalendarDisplay = ({ props }) => {
  const [availability, setAvailability] = useState({});
  console.log(props.pseudo)
  useEffect(() => {
    const fetchVoyantIdAndPlanning = async () => {
      if (props.pseudo) {
        // Requête pour trouver l'ID du voyant à partir de son pseudo
        const voyantsRef = collection(db, 'Voyants');
        const q = query(voyantsRef, where('pseudo', '==', props.pseudo));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Supposons que les pseudos sont uniques, donc on prend le premier résultat
          const voyantDoc = querySnapshot.docs[0];
          const voyantId = voyantDoc.id;

          // Récupération du planning en utilisant l'ID du voyant
          const planningRef = doc(db, 'Voyants', voyantId, 'planning', 'planningData');
          const planningDoc = await getDoc(planningRef);
          if (planningDoc.exists()) {
            setAvailability(planningDoc.data());
          } else {
            console.log('No planning document found for this voyant.');
          }
        } else {
          console.log('No voyant found with this pseudo.');
        }
      } else {
        console.log('No voyant pseudo provided.');
      }
    };

    fetchVoyantIdAndPlanning();
  }, []);

  return (
    <CalendarContainer>
      <ResponsiveCalendarContainer>
        <DayContainer>
          <DayColumn />
          <TimeLabelContainer>
            {hoursOfDay.map((hours, hourIndex) => (
              <TimeLabel key={`time-${hourIndex}`}>
                {hours}
              </TimeLabel>
            ))}
          </TimeLabelContainer>
        </DayContainer>
        {daysOfWeek.map(day => (
          <DayContainer key={day}>
            <DayColumn>
              <DayLabel>{day}</DayLabel>
            </DayColumn>
            <TimeSlotContainer>
              {hoursOfDay.map((hours, hourIndex) => (
                quarts.map((quart, index) => (
                  <TimeSlot
                    key={`${day}-${hours}-${index}`}
                    index={index}
                    color={availability[day]?.[`${hours}-${index}`] || ''}
                  />
                ))
              ))}
            </TimeSlotContainer>
          </DayContainer>
        ))}
      </ResponsiveCalendarContainer>
    </CalendarContainer>
  );
};

export default WeeklyCalendarDisplay;
