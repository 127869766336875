import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { useAuth } from '../contexts/AuthProvider';
import LogoImage from '../assets/divinium2.png';
import LogoImage2 from '../assets/d.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hamburger from 'hamburger-react';
import { theme } from '../theme/theme';
import { faUser, faHeadphones, faPhone, faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import VideoSlogan from '../assets/slogan.mp4';


const underlineAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;


const HeaderContainer = styled.header`
  margin-bottom: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${theme.secondColor};
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
`;

const LogoContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
  justify-content: space-between; 
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const LogoContainer2 = styled.div`
  display: none;

  @media (max-width: 1024px) {
    width: 100%;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
    padding-left: 20px;
    justify-content: space-between; 
    display: flex;
  }
`;

const NavLinks = styled.nav`
  background-color: ${theme.mainColor};
  width: 100%;
  padding-bottom: 20px;
  padding-top: 15px;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    display: none;
  }

  a {
    position: relative;
    color: #fff;
    text-decoration: none;
    margin: 0 20px;

    &.active::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #DFB01A;
      bottom: -5px;
      left: 0;
    }

    &:hover::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #DFB01A;
      bottom: -5px;
      left: 0;
      animation: ${underlineAnimation} 0.3s forwards;
    }
  }
`;

const LogoImageStyled = styled.img`
  height: 80px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const LogoImageStyled2 = styled.img`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    height: 30px;
  }
`;

const AccountLinks = styled.div`
  // align-content: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Button = styled(Link)`
  padding: 10px 20px;
  background-color: #DFB01A;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #B48E15;
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1A1A1A;
  width: 100%;
  position: absolute;
  top: 52px;
  left: 0;
  padding-top: 2px;
  overflow: hidden;
  z-index: 10;
  max-width: 0;
  height: 100vh;
  transition: max-width 0.3s ease-out;
  padding: 0px 0px;


  ${({ isOpen }) =>
    isOpen &&
    css`
      max-width: 100vw;
    `}

    a {
    color: #fff;
    text-decoration: none;
    padding: 15px 0px;
    width: 100%;
    padding-left: 20px;

    &:hover {
      background-color: #333;
    }
    font-size: 13px;
  }
`;

const MenuSeparator = styled.div`
  background-color: #4D4631;
  height: 0.8px;
  width: 100%;
  margin-left: 20px;
`;

const MenuSeparator2 = styled.div`
  background-color: #4D4631;
  height: 0.8px;
  width: 100%;
  margin-left: 40px;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    cursor: pointer;
  }

  .chevron {
    transition: transform 0.3s ease;
    ${({ isOpen }) =>
    isOpen &&
    css`
        transform: rotate(90deg);
      `}
  }

`;


const DropdownContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${theme.mainColor};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: auto;
  border-radius: 4px;
  padding: 10px 0;

  a {
    color: ${theme.lightGray};
    margin: 20px 20px;
    text-decoration: none;
    display: block;
    white-space: nowrap;
  }
`;

const MobileDropdownContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: black;
  

  a {
    color: #fff;
    padding: 15px 40px;
    display: block;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }  
`;

const VideoStyled = styled.video`

  height: 80px;
`;

function Header() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <LogoImageStyled src={LogoImage} alt="Logo" />
        </Link>
        <VideoStyled autoPlay loop muted playsInline>
          <source src={VideoSlogan} type="video/mp4" />
          Votre navigateur ne supporte pas les vidéos HTML5.
        </VideoStyled>
        <div style={{ display: "flex", flexDirection: "row", minWidth: 400, justifyContent: "right" }}>
          <div style={{ flexDirection: "column", marginRight: 30 }}>
            <p style={{ margin: 0, color: "#DFB01A", fontWeight: "bold" }}>Voyance en privé</p>
            <p style={{ fontSize: 16, color: "#DFB01A", fontWeight: "600", border: "1px solid #DFB01A", padding: "6px 0px" }}>
              <FontAwesomeIcon style={{ fontSize: '13px', paddingLeft: "10px", paddingRight: "6px" }} icon={faPhone} /> <a href={`tel:0189472174`} style={{ textDecoration: 'none' }}><span style={{ backgroundColor: "#DFB01A", padding: "6px 10px", color: "black" }}> 01 89 47 21 74</span></a>
            </p>
          </div>
          <div style={{ flexDirection: "column", marginRight: 30 }}>
            <p style={{ margin: 0, color: "#DFB01A", fontWeight: "bold" }}>24h/24 sans CB</p>
            <p style={{ fontSize: 16, color: "#DFB01A", fontWeight: "600", border: "1px solid #DFB01A", padding: "6px 0px" }}>
              <FontAwesomeIcon style={{ fontSize: '13px', paddingLeft: "10px", paddingRight: "6px" }} icon={faPhone} /> <a href={`tel:0895795932`} style={{ textDecoration: 'none' }}><span style={{ backgroundColor: "#DFB01A", padding: "6px 10px", color: "black" }}> 08 95 79 59 32</span></a>
            </p>
          </div>
        </div>
      </LogoContainer>
      <LogoContainer2>
        <Link to="/">
          <LogoImageStyled2 src={LogoImage2} alt="Logo" />
        </Link>
        <a href={`tel:0189472174`} style={{ textDecoration: 'none' }}>
          <p style={{ fontSize: 12, color: "#DFB01A", fontWeight: "600", border: "1px solid #DFB01A", padding: "6px 0px", marginLeft: 20 }}>
            <FontAwesomeIcon style={{ fontSize: '13px', paddingLeft: "10px", paddingRight: "6px" }} icon={faPhone} /> <span style={{ backgroundColor: "#DFB01A", padding: "6px 10px", color: "black" }}> 01 89 47 21 74</span>
          </p>
        </a>
        <Hamburger style={{ display: "flex", flex: 1, width: "100%" }} color={"#DFB01A"} size={25} toggled={isOpen} toggle={toggleMenu} />
      </LogoContainer2>
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", boxShadow: "0px 8px 10px 0px rgba(0, 0, 0, 0.5)", zIndex: 5 }}>
        <NavLinks>
          <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Accueil</Link>
          <DropdownContainer
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <DropdownButton to="/consultationprivate" className={location.pathname.startsWith('/consultationprivate') ? 'active' : ''}>
              Consultations
            </DropdownButton>
            <DropdownContent show={showDropdown}>
              <Link to="/consultationprivate">Consultation privée</Link>
              <Link to="/consultationaudio">Consultation Audiotel sans CB</Link>
            </DropdownContent>
          </DropdownContainer>
          <Link to="/nosvoyants" className={location.pathname === '/nosvoyants' ? 'active' : ''}>Nos voyants</Link>
          <Link to="/horoscope" className={location.pathname === '/horoscope' ? 'active' : ''}>Horoscopes</Link>
          {/* <Link to="/services" className={location.pathname === '/services' ? 'active' : ''}>Services</Link> */}
          <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>À propos</Link>
          <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Link>
          {/* <Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>Blog</Link> */}
          <Link to="/all-articles" className={location.pathname === '/all-articles' ? 'active' : ''}>Actualités</Link>
          <Link to="/guestbook" className={location.pathname === '/guestbook' ? 'active' : ''}>Livre d'or</Link>

          <AccountLinks>
            {currentUser ? (
              <Button to="/account"><FontAwesomeIcon style={{ fontSize: '20px' }} icon={faUser} /></Button>
            ) : (
              <>
                <Button to="/login">Connexion</Button>
                <Button to="/register">Inscription</Button>
              </>
            )}
          </AccountLinks>
        </NavLinks>
      </div>
      <DropdownMenu isOpen={isOpen}>
        <Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={() => setOpen(false)}>Accueil</Link>
        <MenuSeparator />
        <DropdownContainer>
          <DropdownButton onClick={() => setShowMobileDropdown(!showMobileDropdown)}>
            <FontAwesomeIcon style={{ fontSize: 10, marginBottom: "-2px", color: "grey", marginRight: 5 }} className="chevron" icon={faChevronRight} /> Consultations
          </DropdownButton>
          <MobileDropdownContent show={showMobileDropdown}>
            <Link to="/consultationprivate" onClick={() => setOpen(false)}>Consultation privée</Link>
            <MenuSeparator2 />
            <Link to="/consultationaudio" onClick={() => setOpen(false)}>Consultation Audiotel sans CB</Link>
          </MobileDropdownContent>
        </DropdownContainer>
        <MenuSeparator />
        <Link to="/nosvoyants" className={location.pathname === '/nosvoyants' ? 'active' : ''} onClick={() => setOpen(false)}>Nos voyants</Link>
        <MenuSeparator />
        <Link to="/horoscope" className={location.pathname === '/horoscope' ? 'active' : ''} onClick={() => setOpen(false)}>Horoscopes</Link>
        <MenuSeparator />
        {/* <Link to="/services" className={location.pathname === '/services' ? 'active' : ''} onClick={() => setOpen(false)}>Services</Link>
        <MenuSeparator/> */}
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''} onClick={() => setOpen(false)}>À propos</Link>
        <MenuSeparator />
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''} onClick={() => setOpen(false)}>Contact</Link>
        <MenuSeparator />
        {/* <Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''} onClick={() => setOpen(false)}>Blog</Link>
        <MenuSeparator/> */}
        <Link to="/all-articles" className={location.pathname === '/all-articles' ? 'active' : ''} onClick={() => setOpen(false)}>Actualités</Link>
        <MenuSeparator />
        <Link to="/guestbook" className={location.pathname === '/guestbook' ? 'active' : ''} onClick={() => setOpen(false)}>Livre d'or</Link>
        <span style={{ marginBottom: 50 }} />
        {currentUser ? (
          <Link to="/account" className={location.pathname === '/account' ? 'active' : ''} onClick={() => setOpen(false)}>Mon compte</Link>
        ) : (
          <>
            <Link to="/login" className={location.pathname === '/login' ? 'active' : ''} onClick={() => setOpen(false)}>Se connecter</Link>
            <MenuSeparator />
            <Link to="/register" className={location.pathname === '/register' ? 'active' : ''} onClick={() => setOpen(false)}>Inscrivez-vous</Link>
          </>
        )}
      </DropdownMenu>
    </HeaderContainer>
  );
}

export default Header;
