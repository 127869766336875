import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: #DFB01A;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    background-color: #B48E15;
  }
`;

export default StyledButton;
