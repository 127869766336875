import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import LogoImage from '../assets/divinium3.png'; 
import Livre from '../assets/livre.png'; 
import { theme } from '../theme/theme';
import styled, { keyframes } from 'styled-components';
import { StyledInput, StyledTextarea } from '../components/StyledInput'; // Importez vos composants stylisés
import { Helmet } from 'react-helmet-async';
import { isValid } from 'date-fns';


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const DescriptionContainer = styled.div`
    margin: 0 20%;
   
    @media (max-width: 1024px) {
        width: 80%;
        margin: 0 10%;
    }
`;

const LogoImageStyled2 = styled.img`
opacity: 0;
  animation: ${fadeIn} 3s ease forwards;
  width: 60%;
  margin: 0 20%;
  margin-top: 50px;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 10%;
    margin-top: 40px;
  }
`;


const GuestBookContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.thirdColor};
    width: 100%
    margin: 0 auto;
    padding-bottom: 100px;
`;


const CommentContainer = styled.div`
  background-color: #353639;
  // border-radius: 10px;
  margin: 10px 20%;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */
  
  @media (max-width: 768px) {
    padding: 10px 10px;
    margin: 10px 5%;
  }

  `;

const BorderContainer = styled.div`
border: 0.5px solid #C4A641;
  background-color: #5A5B5E;
  padding: 10px 20px;
  // border-radius: 5px;
box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
  }
`;

const PrevButton = styled(Button)`
  margin-right: 5px;
`;

const Form = styled.form`
  margin-top: 20px;

  div {
    margin-bottom: 10px;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  color: white;
  font-weight: 100;
  margin: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 20px 0px;
  }
`;

const StarContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
`;

const Star = styled.span`
  font-size: 24px;
  cursor: pointer;
  color: ${({ filled }) => (filled ? '#FFD700' : '#ccc')};
`;

const Separator = styled.span`
  width: 10%;
  height: 0.5px;
  background-color: #2A2B2C;
  margin: 0px 45%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 20%;
    margin: 0px 40%;
  }

`;


const GuestBook = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [newComment, setNewComment] = useState({
    author: '',
    recipient: '',
    date: '',
    content: '',
    rating: 0
  });
  const [comments, setComments] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [commentsToShow, setCommentsToShow] = useState(5); // Ajout de l'état pour le nombre de commentaires affichés

  // Récupérer les commentaires depuis Firestore
  useEffect(() => {
    const fetchComments = async () => {
      const querySnapshot = await getDocs(collection(db, 'comments'));
      const commentsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // Trier les commentaires par date de manière décroissante
      commentsData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setComments(commentsData);
    };

    fetchComments();
  }, []);

  // Observer l'état de l'authentification
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment({
      ...newComment,
      [name]: value
    });
  };

  const handleRatingChange = (rating) => {
    setNewComment({
      ...newComment,
      rating
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const commentToSave = {
          ...newComment,
          author: user.displayName,
          date: new Date().toISOString()
        };
        await addDoc(collection(db, 'comments'), commentToSave);
        setComments([commentToSave, ...comments]); // Ajouter le nouveau commentaire en tête de liste
        setNewComment({
          author: '',
          recipient: '',
          date: '',
          content: '',
          rating: 0
        });
      } catch (error) {
        console.error('Error adding comment: ', error);
      }
    }
  };

  const handleShowMore = () => {
    setCommentsToShow(prev => prev + 5); // Augmenter le nombre de commentaires affichés de 5
  };

  return (
    <GuestBookContainer>
      <Helmet>
        <title>Livre d'or - Divinium</title>
        <meta name="description" content="Plongez dans les expériences uniques partagées par nos précieux consultants. Notre livre d'or est une collection de récits authentiques qui témoignent de l'impact profond de nos voyants et voyantes sur la vie de nos clients." />
        <meta name="keywords" content="livre d'or, témoignages, avis, voyants, voyance, Divinium" />
      </Helmet>
      <DescriptionContainer>
        <LogoImageStyled2 src={Livre} alt="Logo" />
        <Title style={{color: "white"}}>Explorez les Témoignages de nos Consultations de Voyance</Title>
        <p style={{color: "white", textAlign: "center"}}>Plongez dans les expériences uniques partagées par nos précieux consultants. Notre livre d'or est une collection de récits authentiques qui témoignent de l'impact profond de nos voyants et voyantes sur la vie de nos clients. Des émotions, des révélations et des conseils précieux sont partagés ici, offrant un aperçu de ce que vous pouvez attendre de nos consultations de voyance.</p> <br /> <br />
      </DescriptionContainer>
      
      <div>
        {comments.slice(0, commentsToShow).map((comment) => (
          <CommentContainer key={comment.id}>
            <div style={{display : "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center"}}>
              <div>
                {Array(5).fill().map((_, i) => (
                    <Star style={{cursor : "default"}} key={i} filled={i < comment.rating}>★</Star>
                ))}
              </div>
              <span style={{color: "#C4C4C4", fontSize: 12, fontStyle: "italic"}}>
                {isValid(new Date(comment.date))
                  ? format(new Date(comment.date), "'Le' dd/MM/yy 'à' HH'h'mm", { locale: fr })
                  : "Date non valide"
                }
              </span>
            </div>
              <br />
              <strong style={{color: "#C4C4C4"}}>{comment.author} </strong>
              <span style={{color: "#C4A641", fontWeight:"600"}}>@{comment.recipient}</span>
              <br />
              <br />
              <BorderContainer>
              <span style={{color: "#C4C4C4"}}>
              {comment.content}
              </span>
              </BorderContainer>
              <br />
          </CommentContainer>
        ))}
      </div>
      <div style={{textAlign:"center", marginTop: 20, marginBottom: 20}}>
        <Button style={{backgroundColor: "#DFB01A"}} onClick={handleShowMore} disabled={commentsToShow >= comments.length}>
            Voir plus
        </Button>
      </div>
      <Separator/>
      {currentUser ? (
        <CommentContainer>
          <SubTitle>Ajouter un commentaire</SubTitle>
          <Form onSubmit={handleSubmit}>
            <div>
              <label style={{color: "#C4C4C4"}} htmlFor="recipient">Nom du voyant :</label>
              <StyledInput
                type="text"
                id="recipient"
                name="recipient"
                value={newComment.recipient}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{color: "#C4C4C4"}} htmlFor="content">Commentaire :</label>
              <StyledTextarea
                id="content"
                name="content"
                value={newComment.content}
                onChange={handleChange}
              />
              </div>
            <div style={{display : "flex", flexDirection: "row", justifyContent:"space-between", alignItems: "center"}}>
            <div>
              <label>Note :</label>
              <StarContainer>
                {Array(5).fill().map((_, i) => (
                  <Star
                    key={i}
                    filled={i < newComment.rating}
                    onClick={() => handleRatingChange(i + 1)}
                    >★</Star>
                  ))}
                </StarContainer>
              </div>
              <Button style={{backgroundColor: "#DFB01A"}} type="submit">Ajouter</Button>
            </div>
          </Form>
        </CommentContainer>
      ) : (
        <CommentContainer>
          <p>Connectez-vous pour ajouter un commentaire.</p>
        </CommentContainer>
      )}
      
    </GuestBookContainer>
  );
  
  };
  
  export default GuestBook;