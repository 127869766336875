// src/components/Card.js

import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  flex: 0 0 calc(100% / 5 - 10px); /* Adjust for margin */
  box-sizing: border-box;
  margin: 0 5px; /* Add margin to each card for spacing */
  padding: 5px;
  text-align: center;
  border: 1px solid #AFA46E; /* Bordure de 2 pixels de couleur gris clair */
  border-radius: 10px; /* Arrondir les coins avec un radius de 10 pixels */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */
  cursor: pointer;

  @media (max-width: 1024px) {
    flex: 0 0 calc(100% / 3 - 10px); /* Adjust for margin */
  }

  @media (max-width: 600px) {
    flex: 0 0 calc(100% - 10px); /* Adjust for margin */
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 180px; /* Hauteur fixe */
  object-fit: cover; /* Pour s'assurer que l'image couvre tout l'espace */
  border-radius: 8px;

  @media (max-width: 1024px) {
    height: 200px; /* Hauteur fixe pour les tablettes */
  }

  @media (max-width: 600px) {
    height: 200px; /* Hauteur fixe pour les téléphones */
  }
`;

const ProfileName = styled.h3`
  margin: 10px 0 5px 0;
  font-size: 15px;
  color: #EEEEEE;
  font-weight: 400;
`;

const ProfileStatus = styled.p`
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'Disponible':
        return 'green';
      case 'En communication':
        return 'orange';
      case 'Prendre rendez-vous':
        return 'white';
      default:
        return '#333';
    }
  }};
`;

const Card = ({ profile, onClick }) => (
<CardContainer onClick={() => onClick(profile)}>
    <ProfileImage src={profile.avatarUrl} alt={profile.name} />
    <ProfileName>{profile.pseudo || profile.name}</ProfileName>
    <ProfileStatus status={profile.status}>{profile.status}</ProfileStatus>
  </CardContainer>
);

export default Card;
