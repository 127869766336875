import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';

const Welcome = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleViewSeers = () => {
    navigate('/services');  // Assuming services page shows the voyants
  };

  const handleBuyMinutes = () => {
    navigate('/buy-minutes');  // Create this route/page for purchasing minutes
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Bienvenue, {currentUser.displayName || currentUser.email}!</h1>
      <p>Votre compte a été créé avec succès.</p>
      <div style={{ marginTop: '20px' }}>
        <button onClick={handleViewSeers} style={buttonStyle}>
          Voir les voyants
        </button>
        <button onClick={handleBuyMinutes} style={buttonStyle}>
          Acheter des minutes
        </button>
      </div>
    </div>
  );
};

const buttonStyle = {
  margin: '10px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
};

export default Welcome;