import styled from 'styled-components';

const StyledButton = styled.a`
  padding: 10px 20px;
  background-color: #DFB01A;
  color: #fff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    background-color: #B48E15;
  }
`;

export default StyledButton;
