import styled from 'styled-components';

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  accent-color: ${props => props.theme.checkboxColor || '#B69323'};
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 10px;
    height: 10px;
  }
  
  &:checked {
    background-color: ${props => props.theme.checkboxCheckedColor || '#B69323'};
    border-color: ${props => props.theme.checkboxCheckedColor || '#B69323'};
  }
`;

export default StyledCheckbox;
