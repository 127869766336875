// theme.js

export const theme = {
  primaryColor: "#DFB01A",
    mainColor: '#1A1A1A', // Couleur principale par défaut
    // Autres variables globales
    secondColor: '#212121', // Couleur principale par défaut

    thirdColor : '#0A0A0A',
    containerColor: '#303030',
    textColor: '#C4C4C4',
  };
  