import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import articlesData from '../articlesData'; // Import des données des articles
import { theme } from '../theme/theme';

const ArticleContainer = styled.div`
  background-color: ${theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
`;

const ArticleCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 60%;
  max-width: 800px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const ArticleTitle = styled.h3`
  color: #333;
`;

const ArticleContent = styled.p`
  color: #666;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: ${theme.secondaryColor};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: ${theme.secondaryColorDark};
  }
`;

const ArticleDate = styled.p`
  color: gray;
  font-size: 12px;
`;

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const article = articlesData.find((article) => article.id === parseInt(id));

  if (!article) {
    return (
      <ArticleContainer>
        <p style={{ color: '#fff' }}>Cet article n'existe pas.</p>
        <Button onClick={() => navigate('/')}>Retour à l'accueil</Button>
      </ArticleContainer>
    );
  }

  return (
    <ArticleContainer>
      <ArticleCard>
        <ArticleTitle>{article.title}</ArticleTitle>
        <ArticleDate>{article.date}</ArticleDate>
        <ArticleContent>{article.content}</ArticleContent>
        <Link to="/">Retour à l'accueil</Link>
      </ArticleCard>
    </ArticleContainer>
  );
};

export default Article;
