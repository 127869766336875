const articlesData = [
  { id: 1, title: "Quelle Influence de la Pleine Lune de Juin ?", content: "La pleine lune de juin, souvent appelée la Pleine Lune des Fraises, est un événement astrologique fascinant qui tire son nom de la saison de récolte des fraises. Cette pleine lune a une influence particulière sur les émotions et les énergies.", date: '15 juin 2024' },
  { id: 2, title: "L'Influence Mystique de la Pleine Lune d'Avril", content: "Préparez-vous à plonger dans le royaume mystique de la Pleine Lune d'Avril ! Cette lueur céleste, symbole de puissance et de transformation, exerce une influence profonde sur nos vies.", date: '15 juin 2024' },
  { id: 3, title: "Prédictions Astrologiques d'Avril", content: "En ce mois d'Avril, les astres nous réservent une multitude de surprises et d'opportunités selon notre signe du zodiaque. Plongeons dans les prédictions astrologiques pour chaque signe.", date: '15 juin 2024' },
  { id: 1, title: "Quelle Influence de la Pleine Lune de Juin ?", content: "La pleine lune de juin, souvent appelée la Pleine Lune des Fraises, est un événement astrologique fascinant qui tire son nom de la saison de récolte des fraises. Cette pleine lune a une influence particulière sur les émotions et les énergies.", date: '15 juin 2024' },
    { id: 2, title: "L'Influence Mystique de la Pleine Lune d'Avril", content: "Préparez-vous à plonger dans le royaume mystique de la Pleine Lune d'Avril ! Cette lueur céleste, symbole de puissance et de transformation, exerce une influence profonde sur nos vies.", date: '15 juin 2024' },
    { id: 3, title: "Prédictions Astrologiques d'Avril", content: "En ce mois d'Avril, les astres nous réservent une multitude de surprises et d'opportunités selon notre signe du zodiaque. Plongeons dans les prédictions astrologiques pour chaque signe.", date: '15 juin 2024' },
    { id: 1, title: "Quelle Influence de la Pleine Lune de Juin ?", content: "La pleine lune de juin, souvent appelée la Pleine Lune des Fraises, est un événement astrologique fascinant qui tire son nom de la saison de récolte des fraises. Cette pleine lune a une influence particulière sur les émotions et les énergies.", date: '15 juin 2024' },
    { id: 2, title: "L'Influence Mystique de la Pleine Lune d'Avril", content: "Préparez-vous à plonger dans le royaume mystique de la Pleine Lune d'Avril ! Cette lueur céleste, symbole de puissance et de transformation, exerce une influence profonde sur nos vies.", date: '15 juin 2024' },
    { id: 3, title: "Prédictions Astrologiques d'Avril", content: "En ce mois d'Avril, les astres nous réservent une multitude de surprises et d'opportunités selon notre signe du zodiaque. Plongeons dans les prédictions astrologiques pour chaque signe.", date: '15 juin 2024' },
    { id: 1, title: "Quelle Influence de la Pleine Lune de Juin ?", content: "La pleine lune de juin, souvent appelée la Pleine Lune des Fraises, est un événement astrologique fascinant qui tire son nom de la saison de récolte des fraises. Cette pleine lune a une influence particulière sur les émotions et les énergies.", date: '15 juin 2024' },
    { id: 2, title: "L'Influence Mystique de la Pleine Lune d'Avril", content: "Préparez-vous à plonger dans le royaume mystique de la Pleine Lune d'Avril ! Cette lueur céleste, symbole de puissance et de transformation, exerce une influence profonde sur nos vies.", date: '15 juin 2024' },
    { id: 3, title: "Prédictions Astrologiques d'Avril", content: "En ce mois d'Avril, les astres nous réservent une multitude de surprises et d'opportunités selon notre signe du zodiaque. Plongeons dans les prédictions astrologiques pour chaque signe.", date: '15 juin 2024' },
    // Ajoutez plus d'articles ici
  ];
  
  export default articlesData;
  