import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faBahai, faCertificate  } from '@fortawesome/free-solid-svg-icons';

// Animation keyframes pour le défilement continu
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

// Container pour le marquee
const MarqueeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #1A1A1A; // Couleur de fond
  padding: 10px 0;
  position: relative;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

// Inner wrapper pour le contenu défilant
const MarqueeWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  animation: ${scroll} 30s linear infinite;
`;

const Review = styled.span`
  display: inline-block;
  margin-right: 50px;
  font-size: 13px; // Ajustez la taille de la police si nécessaire
  background-color: "red";

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;


function ScrollingReviews() {
  const reviews = [
    "Incroyable expérience, très précis et utile. - Jean Dupont",
    "Les prédictions étaient très justes. - Marie Curie",
    "Service client exceptionnel et voyance de qualité. - Alice Martin",
    "Je recommande fortement. - Pierre Rousseau",
    "Une expérience unique et enrichissante. - Sophie Lemaire",
    // Ajoutez plus d'avis ici
  ];

  // Dupliquez les avis pour créer un effet de boucle continue
  const repeatedReviews = [...reviews, ...reviews];

  return (
    <MarqueeContainer>
      <MarqueeWrapper>
        {repeatedReviews.map((review, index) => (
            <div style={{backgroundColor: "#232323", marginRight: 30, display: "flex", flexDirection: "row", width: "100%",  justifyContent: "center", padding: "10px 20px", borderRadius: 50}}>
                <Review key={index} style={{margin: 0, color: "#EFEFEF"}}><FontAwesomeIcon style={{ fontSize: '10px', marginRight: 5 }} icon={faSun} />   {review}</Review>
            </div>
        ))}
      </MarqueeWrapper>
    </MarqueeContainer>
  );
}

export default ScrollingReviews;
