import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import HoroscopePage from './HoroscopePage';
import { Helmet } from 'react-helmet-async';

import belier from '../assets/Astro/Belier1.png';
import taureau from '../assets/Astro/taureau1.png';
import gemau from '../assets/Astro/gemau1.png';
import cancer from '../assets/Astro/cancer1.png';
import lion from '../assets/Astro/lion1.png';
import vierge from '../assets/Astro/vierge1.png';
import balance from '../assets/Astro/balance1.png';
import scorpion from '../assets/Astro/scorpion1.png';
import sagitaire from '../assets/Astro/sagitaire1.png';
import capri from '../assets/Astro/capri1.png';
import verseau from '../assets/Astro/verseau1.png';
import poisson from '../assets/Astro/poisson1.png';
import { useLocation } from 'react-router-dom';

const zodiacSigns = [
  { name: 'Bélier', image: belier, path: "belier" },
  { name: 'Taureau', image: taureau, path: "taureau" },
  { name: 'Gémeaux', image: gemau, path: "gemeaux" },
  { name: 'Cancer', image: cancer, path: "cancer" },
  { name: 'Lion', image: lion, path: "lion" },
  { name: 'Vierge', image: vierge, path: "vierge" },
  { name: 'Balance', image: balance, path: "balance" },
  { name: 'Scorpion', image: scorpion, path: "scorpion" },
  { name: 'Sagittaire', image: sagitaire, path: "sagittaire" },
  { name: 'Capricorne', image: capri, path: "capricorne" },
  { name: 'Verseau', image: verseau, path: "verseau" },
  { name: 'Poissons', image: poisson, path: "poissons" },
];

const HoroscopeContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const ZodiacCircle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 40px 0;

  @media (max-width: 768px) {
    font-size: 22px;
    width: auto;
  }
`;

const ZodiacSign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  cursor: pointer;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: black;
    padding: 10px;
    transition: transform 0.3s ease;
  }

  img:hover {
    transform: scale(1.2);
  }

  span {
    color: white;
    margin-top: 10px;
    font-size: 16px;
  }
`;

const InputContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  input {
    background-color: #5A5B5E;
    border: 1px solid #5A5B5E;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    color: white;
    box-sizing: border-box;
    margin-bottom: 10px;

    &:focus {
      outline: none;
      border: 1px solid  #C4A641;
      box-shadow: 0 0 10px #C4A641;
    }
  }

  button {
    padding: 10px 20px;
    background-color: #DFB01A;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #B48E15;
    }
  }
`;

const Horoscope = () => {
  const [selectedSign, setSelectedSign] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  const [userSign, setUserSign] = useState(null);
  const [signImage, setSignImage] = useState(null);
  const [signPath, setSignPath] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sign = params.get('sign');

  useEffect(() => {
    if (sign) {
      handleSignClick(sign);
    }
  }, [sign]);

  const handleSignClick = (sign) => {
    const selectedZodiac = zodiacSigns.find(z => z.name === sign);
    setSelectedSign(sign);
    setSignImage(selectedZodiac.image);
    setSignPath(selectedZodiac.path)
    setSelectedPeriod('jour');
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const handlePeriodClick = (period) => {
    setSelectedPeriod(period);
  };

  const handleBirthDateChange = (e) => {
    setBirthDate(e.target.value);
  };

  const handleBackClick = () => {
    setSelectedSign(null);
    setSelectedPeriod(null);
    setSignImage(null);
    setSignPath(null)
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const convertDateToArray = (dateString) => {
    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0]);
    const month = new Date(year, parseInt(dateParts[1]) - 1).toLocaleString('en-us', { month: 'long' });
    const day = parseInt(dateParts[2]);
    return [month, day, year];
  };

  const ZodiacIdentifier = (dateInput) => {
    const birthDate = convertDateToArray(dateInput);
    const m = birthDate[0].toLowerCase();
    const d = birthDate[1];
    if (m === "december") {
      if (d >= 1 && d <= 21) { return "Sagittaire"; }
      else if (d >= 22 && d <= 31) { return "Capricorne"; }
    } else if (m === "january") {
      if (d >= 1 && d <= 19) { return "Capricorne"; }
      else if (d >= 20 && d <= 31) { return "Verseau"; }
    } else if (m === "february") {
      if (d >= 1 && d <= 18) { return "Verseau"; }
      else if (d >= 19 && d <= 29) { return "Poissons"; }
    } else if (m === "march") {
      if (d >= 1 && d <= 20) { return "Poissons"; }
      else if (d >= 21 && d <= 31) { return "Bélier"; }
    } else if (m === "april") {
      if (d >= 1 && d <= 19) { return "Bélier"; }
      else if (d >= 20 && d <= 30) { return "Taureau"; }
    } else if (m === "may") {
      if (d >= 1 && d <= 20) { return "Taureau"; }
      else if (d >= 21 && d <= 31) { return "Gémeaux"; }
    } else if (m === "june") {
      if (d >= 1 && d <= 20) { return "Gémeaux"; }
      else if (d >= 21 && d <= 30) { return "Cancer"; }
    } else if (m === "july") {
      if (d >= 1 && d <= 22) { return "Cancer"; }
      else if (d >= 23 && d <= 31) { return "Lion"; }
    } else if (m === "august") {
      if (d >= 1 && d <= 22) { return "Lion"; }
      else if (d >= 23 && d <= 31) { return "Vierge"; }
    } else if (m === "september") {
      if (d >= 1 && d <= 22) { return "Vierge"; }
      else if (d >= 23 && d <= 30) { return "Balance"; }
    } else if (m === "october") {
      if (d >= 1 && d <= 22) { return "Balance"; }
      else if (d >= 23 && d <= 31) { return "Scorpion"; }
    } else if (m === "november") {
      if (d >= 1 && d <= 21) { return "Scorpion"; }
      else if (d >= 22 && d <= 30) { return "Sagittaire"; }
    }
    return null;
  };

  const handleDateValidation = () => {
    const sign = ZodiacIdentifier(birthDate);
    setUserSign(sign);
  };

  return (
    <HoroscopeContainer>
      <Helmet>
        <title>Horoscope - Découvrez votre signe astrologique | NomDuSite</title>
        <meta name="description" content="Découvrez votre signe astrologique et lisez votre horoscope quotidien. Entrez votre date de naissance et explorez les prévisions pour votre signe." />
      </Helmet>
      {!selectedSign && (
        <>
          <Title>Sélectionnez votre signe du zodiaque</Title>
          <ZodiacCircle>
            {zodiacSigns.map((sign) => (
              <ZodiacSign key={sign.name} onClick={() => handleSignClick(sign.name)}>
                <img src={sign.image} alt={sign.name} />
                <span>{sign.name}</span>
              </ZodiacSign>
            ))}
          </ZodiacCircle>
          <InputContainer>
            <span>Vous ne connaissez pas votre signe ?</span>
            <span>Entrez une date de naissance</span>
            <input type="date" value={birthDate} onChange={handleBirthDateChange} />
            <button onClick={handleDateValidation}>JE VALIDE</button>
            {userSign && <span>Votre signe du zodiaque est : {userSign}</span>}
          </InputContainer>
        </>
      )}
      {selectedSign && (
        <HoroscopePage
          selectedSign={selectedSign}
          selectedPeriod={selectedPeriod}
          handlePeriodClick={handlePeriodClick}
          handleBackClick={handleBackClick}
          signImage={signImage}
          signPath={signPath}
        />
      )}
    </HoroscopeContainer>
  );
};

export default Horoscope;

