import React, { useState } from 'react';
import styled from 'styled-components';
import belier from '../assets/Astro/belier.png';
import taureau from '../assets/Astro/taureau.png';
import gemau from '../assets/Astro/gemau.png';
import cancer from '../assets/Astro/cancer.png';
import lion from '../assets/Astro/lion.png';
import vierge from '../assets/Astro/vierge.png';
import balance from '../assets/Astro/balance.png';
import scorpion from '../assets/Astro/scorpion.png';
import sagitaire from '../assets/Astro/sagitaire.png';
import capri from '../assets/Astro/capri.png';
import verseau from '../assets/Astro/verseau.png';
import poisson from '../assets/Astro/poisson.png';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';

const Section = styled.div`
  position: relative;
  width: 60%;
  
`;

const CarouselContainer = styled.div`
  margin-top: 35px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
`;

const Card = styled.div`
  flex: 0 0 calc(100% / 4 - 20px); /* Adjust for margin */
  box-sizing: border-box;
  margin: 0 10px; /* Add margin to each card for spacing */
  padding: 10px;
  text-align: center;
  // border: 1px solid #AFA46E; /* Bordure de 2 pixels de couleur gris clair */
  border-radius: 10px; /* Arrondir les coins avec un radius de 10 pixels */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optionnel : Ajout d'une ombre pour un effet de profondeur */
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0); /* Ajout d'un fond semi-transparent */

  @media (max-width: 1024px) {
    flex: 0 0 calc(100% / 3 - 20px); /* Adjust for margin */
  }

  @media (max-width: 600px) {
    flex: 0 0 calc(100% - 20px); /* Adjust for margin */
  }
`;

const SignImage = styled.img`
  width: 100%; /* Taille de l'image du signe astrologique */
  height: auto;
`;

const SignName = styled.h3`
  margin: 10px 0 5px 0;
  font-size: 18px;
  color: #DFB01A;
  font-weight: 400;
`;

const SignDescription = styled.p`
  font-size: 14px;
  color: white;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #DFB01A;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
  ${({ direction }) => (direction === 'left' ? 'left: -50px;' : 'right: -50px;')}
`;

const zodiacSigns = [
  { id: 1, name: 'Bélier', description: 'Le Bélier est le premier signe du zodiaque. Les Béliers sont des leaders nés, dynamiques et courageux.', image: belier },
  { id: 2, name: 'Taureau', description: 'Les Taureaux sont connus pour leur dévotion, leur fiabilité, leur patience et leur ténacité.', image: taureau },
  { id: 3, name: 'Gémeaux', description: 'Les Gémeaux sont connus pour être adaptables, intelligents, vifs et communicatifs.', image: gemau },
  { id: 4, name: 'Cancer', description: 'Les Cancers sont sensibles, émotionnels, imaginatifs et protecteurs.', image: cancer },
  { id: 5, name: 'Lion', description: 'Les Lions sont généreux, chaleureux, créatifs, enthousiastes, larges d\'esprit et fidèles.', image: lion },
  { id: 6, name: 'Vierge', description: 'Les Vierges sont connues pour leur sens de l\'analyse, leur fiabilité, leur modestie, leur intégrité et leur praticité.', image: vierge },
  { id: 7, name: 'Balance', description: 'Les Balances sont charmantes, diplomates, pacifiques, idéalistes et sociables.', image: balance },
  { id: 8, name: 'Scorpion', description: 'Les Scorpions sont passionnés, déterminés, concentrés, résolus et courageux.', image: scorpion },
  { id: 9, name: 'Sagittaire', description: 'Les Sagittaires sont indépendants, aventureux, enthousiastes et optimistes.', image: sagitaire },
  { id: 10, name: 'Capricorne', description: 'Les Capricornes sont responsables, disciplinés, maîtres de soi et ont un bon sens de l\'humour.', image: capri },
  { id: 11, name: 'Verseau', description: 'Les Verseaux sont progressistes, originaux, indépendants et humanitaires.', image: verseau },
  { id: 12, name: 'Poissons', description: 'Les Poissons sont compatissants, intuitifs, doux et sages.', image: poisson },
];


const CarouselAstro = () => {
  const [lion, setLion] = useState([])
  // const URL = 'https://newastro.vercel.app/aries?date=2024-06-08&lang=fr';
  //       fetch(URL, {
  //           method: 'POST'
  //       }).then(response => response.json())
  //       .then(json => { setLion({json}); });

  // console.log(lion)

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleSignClick = (sign) => {
    navigate(`/horoscope?sign=${sign.name}`);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => prev === 0 ? zodiacSigns.length - getCardsPerPage() : Math.max(prev - (getCardsPerPage()), 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => prev + (getCardsPerPage()) >= zodiacSigns.length ? 0 : Math.min(prev + (getCardsPerPage()), zodiacSigns.length - getCardsPerPage()));
  };

  const getCardsPerPage = () => {
    if (window.innerWidth >= 1024) {
      return 4;
    } else if (window.innerWidth >= 600) {
      return 3;
    } else {
      return 1;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev()
  });

  const translateX = -(currentIndex * (100 / getCardsPerPage())); // Pourcentage pour la translation

  return (
    <Section {...handlers}>
      <Arrow direction="left" onClick={handlePrev}>◀</Arrow>
      <CarouselContainer>
        <CarouselWrapper translateX={translateX}>
          {zodiacSigns.map((sign) => (
            <Card key={sign.id} onClick={() => handleSignClick(sign)}>
              <SignImage src={sign.image} alt={sign.name} />
              <SignName>{sign.name}</SignName>
              <SignDescription>{sign.description}</SignDescription>
            </Card>
          ))}
        </CarouselWrapper>
      </CarouselContainer>
      <Arrow direction="right" onClick={handleNext}>▶</Arrow>
    </Section>
  );
};

export default CarouselAstro;
