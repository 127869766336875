import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import GuestBook from './pages/GuestBook';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import Account from './pages/Account';
import Welcome from './pages/Welcome';
import PurchasePack from './pages/PurchasePack';
import PaymentMethod from './components/PaymentMethod';
import { AuthProvider } from './contexts/AuthProvider';
import GlobalStyle from './GlobalStyle';
import styled from 'styled-components';
import ConsultationAudio from './pages/ConsultationAudiotel';
import WeeklyCalendar from './components/WeeklyCalendar';
import Horoscope from './pages/Horoscope';
import HoroscopeDetail from './components/HoroscopeDetail';
import { theme } from './theme/theme';
import Article from './pages/Article';
import AllArticles from './pages/AllArticles';
import NosVoyants from './pages/NosVoyants';
import ConsultationPrivate from './pages/ConsultationPrivate';
import './fonts/fonts.css';
import { HelmetProvider } from 'react-helmet-async';
 


const Spacer = styled.div`
  height: 150px; /* Hauteur du Header */
  background-color: #${theme.thirdColor};

  @media (max-width: 1024px) {
    height: 55px;
  }
`;


function App() {
  return (
    <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap" rel="stylesheet" />
        </Helmet>
        <GlobalStyle />
        <Header/>
        <Spacer/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/nosvoyants' element={<NosVoyants />} />
          <Route path='/consultationprivate' element={<ConsultationPrivate />} />
          <Route path='/consultationaudio' element={<ConsultationAudio />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/guestbook' element={<GuestBook />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/account' element={<Account />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/buy-minutes" element={<PurchasePack />} />
          <Route path="/payment" element={<PaymentMethod />} />
          <Route path="/horoscope" element={<Horoscope/>} />
          <Route path="/horoscope/:period" element={<HoroscopeDetail/>} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/all-articles" element={<AllArticles/>} />
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
