import React, { useState } from 'react';

const PaymentMethod = ({ onPaymentMethodSelect }) => {
  const [selectedMethod, setSelectedMethod] = useState('');

  const handleSubmit = () => {
    if (selectedMethod) {
      onPaymentMethodSelect(selectedMethod);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Choisissez votre mode de règlement</h2>
      <div style={styles.methodContainer}>
        <label>
          <input
            type="radio"
            name="paymentMethod"
            value="CB"
            checked={selectedMethod === 'CB'}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
          Paiement par carte bancaire (CB)
        </label>
        <label>
          <input
            type="radio"
            name="paymentMethod"
            value="PayPal"
            checked={selectedMethod === 'PayPal'}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
          Paiement par PayPal
        </label>
        <label>
          <input
            type="radio"
            name="paymentMethod"
            value="SEPA"
            checked={selectedMethod === 'SEPA'}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
          Paiement par virement SEPA
        </label>
      </div>
      <button onClick={handleSubmit} style={styles.continueButton} disabled={!selectedMethod}>
        Continuer
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  methodContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  continueButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default PaymentMethod;