import React from 'react';
import styled from 'styled-components';
import WeeklyCalendar from './WeeklyCalendar';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh; /* Max height relative to the viewport height */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  p{
    font-size: 12px;
  }
    
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000;
`;

const ContactButton = styled.button`
  background-color: #C4A641;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
`;

const ProfileImage = styled.img`
  width: 30%;
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensure the image covers the space */
  border-radius: 8px;

  @media (max-width: 1024px) {
    height: 200px; /* Fixed height for tablets */
  }

  @media (max-width: 600px) {
    height: 130px; /* Fixed height for phones */
  }
`;

const Modal = ({ show, onClose, profile }) => {
  if (!show) return null;

  const handleCall = () => {
    window.location.href = `tel:${profile.telephone}`;
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>{profile.pseudo}</h2>
        <div style={{display: "flex", flexDirection: "row"}}>
            <ProfileImage src={profile.avatarUrl} alt={profile.pseudo} />
          <div style={{marginLeft: 15}}>
            <p>Status: {profile.status}</p>
            <p>Description: {profile.description}</p>
            <p>Spécialités: {profile.specialities ? profile.specialities.join(', ') : 'Aucune'}</p>
          </div>
        </div>
        <p>Prix premières 10 min: {profile.prixPremieres10Min}</p>
            <p>Prix par minute supplémentaire: {profile.prixParMinuteSup}</p>
        <WeeklyCalendar props={profile}/>
        <ContactButton onClick={handleCall}>Contactez-moi</ContactButton>
      </ModalContainer>
    </ModalBackground>
  );
};

export default Modal;
