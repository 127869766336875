import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import articlesData from '../articlesData'; // Import des données des articles
import { theme } from '../theme/theme';
import { Helmet } from 'react-helmet-async';

const AllArticlesContainer = styled.div`
  background-color: ${theme.thirdColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ArticleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ArticleCard = styled.div`
  background-color: ${theme.containerColor};
  padding: 20px;
  // border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(33.3333% - 20px);
  

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ArticleTitle = styled.h3`
  margin-top: 5px;
  color: ${theme.textColor};
`;

const ArticleContent = styled.p`
  color: ${theme.textColor};
`;

const ArticleDate = styled.p`
  color: gray;
  font-size: 12px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageNumber = styled.span`
  padding: 10px;
  margin: 0 5px;
  background-color: ${({ active }) => (active ? "#DFB01A" : "#eee")};
  color: ${({ active }) => (active ? "#fff" : "#333")};
  cursor: pointer;
`;

const Title = styled.h1`
  color: white;
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const ITEMS_PER_PAGE = 9;

const AllArticles = () => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(articlesData.length / ITEMS_PER_PAGE);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentArticles = articlesData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <AllArticlesContainer>
      <Helmet>
        <title>L'Actualité Divinium - Découvrez les derniers articles | Divinium</title>
        <meta name="description" content="Découvrez l'actualité Divinium et les derniers articles sur la voyance, la spiritualité et plus encore. Restez à jour avec nos publications et plongez dans un monde de sagesse et de connaissance spirituelle."></meta>
        <meta name="keywords" content="Divinium, articles, actualités, voyance, spiritualité, sagesse, connaissance spirituelle"></meta>
      </Helmet>
      <Title>l'Actualité Divinium</Title>
      <ArticleList>
        {currentArticles.map((article) => (
          <ArticleCard key={article.id}>
            <ArticleTitle>{article.title}</ArticleTitle>
            <ArticleDate>{article.date}</ArticleDate>
            <ArticleContent>{article.content.substring(0, 100)}...</ArticleContent>
            <Link style={{textDecoration: "none", color: "#DFB01A"}} to={`/article/${article.id}`}>Lire la suite...</Link>
            </ArticleCard>
        ))}
      </ArticleList>
      <Pagination>
        {Array.from({ length: totalPages }).map((_, index) => (
          <PageNumber
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handleClick(index + 1)}
          >
            {index + 1}
          </PageNumber>
        ))}
      </Pagination>
    </AllArticlesContainer>
  );
};

export default AllArticles;
