import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthProvider';
import { getAuth, updatePassword, signOut, verifyBeforeUpdateEmail } from 'firebase/auth';
import { theme } from '../theme/theme';
import StyledButton from '../components/StyledButton';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${theme.secondColor};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: ${theme.primaryColor};
  text-align: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: ${theme.textColor};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${theme.borderColor};
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${theme.primaryColor};
  color: ${theme.textColor};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.secondaryColor};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
`;

function Account() {
  const { currentUser } = useAuth();
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleUpdateEmail = async () => {
    const auth = getAuth();
    setError('');
    setSuccess('');
    try {
      await verifyBeforeUpdateEmail(currentUser, newEmail);
      setSuccess('Email updated successfully!');
      setNewEmail('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleUpdatePassword = async () => {
    const auth = getAuth();
    setError('');
    setSuccess('');
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }
    try {
      await updatePassword(auth.currentUser, newPassword);
      setSuccess('Password updated successfully!');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    setError('');
    setSuccess('');
    try {
      await signOut(auth);
      setSuccess('Logged out successfully!');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <Title>Mon Compte</Title>
      {currentUser && <p>Adresse Email: {currentUser.email}</p>}
      <div>
        <Label htmlFor="new-email">Nouvelle Adresse Email</Label>
        <Input
          type="email"
          id="new-email"
          placeholder="Nouvelle Adresse Email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <StyledButton onClick={handleUpdateEmail}>Mettre à jour Email</StyledButton>
      </div>
      <div>
        <Label htmlFor="new-password">Nouveau Mot de passe</Label>
        <Input
          type="password"
          id="new-password"
          placeholder="Nouveau Mot de passe"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Label htmlFor="confirm-password">Confirmer le Mot de passe</Label>
        <Input
          type="password"
          id="confirm-password"
          placeholder="Confirmer le Mot de passe"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <StyledButton onClick={handleUpdatePassword}>Mettre à jour Mot de passe</StyledButton>
      </div>
      <StyledButton onClick={handleLogout}>Se déconnecter</StyledButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
    </Container>
  );
}

export default Account;
